import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { AddAdminComponent } from '@app-admin/dialogs/add-admin/add-admin.component';
import { DialogService } from '@app-admin/dialogs/dialog.service';
import { UtilService } from '@app-shared/services/util.service';
import { DeleteService } from '@app/shared/table/delete.service';
import { GetAllService } from '@app/shared/table/getAll.service';
import { UpdateStatusService } from '@app/shared/table/updateStatus.service';

declare var $: any;
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-manage-admins',
  templateUrl: './manage-admins.component.html',
  styleUrls: ['./manage-admins.component.css']
})
export class ManageAdminsComponent implements OnInit {

  displayedColumns: string[] = ['sno', 'name', 'email', 'phone', 'designation', 'location', 'is_active', 'action'];
  dataSource: MatTableDataSource<any>;
  UserId: any = parseInt(localStorage.getItem('superUserId'));
  isOrgSuperAdmin: boolean = JSON.parse(localStorage.getItem('isOrgSuperAdmin'));
  isSuperAdmin: boolean;
  serviceError: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private srvc: DialogService,
    private util: UtilService,
    private dialog: MatDialog,
    private route: Router,
    private cdr: ChangeDetectorRef,
    private deleteService: DeleteService,
    private updateStatusService: UpdateStatusService,
    private getAllService: GetAllService
  ) {
  }

  ngOnInit(): void {
    this.isSuperAdmin = this.util.decrypt(localStorage.getItem('ur')) === 'SA';
    if (this.isSuperAdmin) {
      const colLength = this.displayedColumns.length;
      this.displayedColumns.splice(colLength - 1, 0, 'is_org_admin');
    }
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getAllAdmins();
  }

  getAllAdmins() {
    this.getAllService.getAdmin(this.data).subscribe(
      (data: any) => {
        const tableData = data.body.responseBody.map((i, index) => {
          i.sno = index + 1;
          i.name = i.full_name;
          i.location = `${i.district_name}, ${i.state_name}`;
          delete i['full_name'];
          return i;
        });
        if (tableData && tableData.length) {
          const loginAdminIndex = tableData.findIndex((ele) => ele.id === this.UserId);
          if (loginAdminIndex !== -1) {
            tableData.splice(loginAdminIndex, 1);
          }
        }
        this.dataSource.data = tableData;
        this.dataSource._updateChangeSubscription();
        this.cdr.markForCheck();
      },
      (err: any) => {
        this.util.errorNotification(err);
        this.cdr.markForCheck();
      }
    );
  }

  addAdminPopup(data?: any) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(AddAdminComponent, {
      width: '100vw',
      autoFocus: false,
      data: data || { orgId: this.data }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.dialog.open(ManageAdminsComponent, {
        width: '100vw',
        data: this.data,
        autoFocus: false,
      });
    });
  }

  modifyAdmin(id) {
    this.srvc.getAdminById(parseInt(id)).subscribe(
      (data: any) => {
        this.addAdminPopup(data.body.responseBody);
      },
      (err: any) => {
        this.util.errorNotification(err);
        this.cdr.markForCheck();
      }
    );
  }

  showRevisionHistory(row: any) {
    this.dialog.closeAll();
    this.route.navigate(['/admin/org-admin/RevisionHistory',row.id,row.name]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateStatus(event, id: number) {
    this.updateStatusService.updateStatus(event, id, false, 'adminStatus').subscribe((data: any) => {
      const responseHead = data.body.responseHead;
      const successMsg = responseHead ? this.util.getSuccessMessage('UPDATE_ADMIN_STATUS', responseHead.statusCode, responseHead.statusDesc) : responseHead;
      this.util.simpleDialog({
        title: 'Updated',
        message: successMsg,
      });
      this.route.navigate(['/admin/org-list']);
      this.util.showLoader = false;
    }, (err) => {
      this.util.showLoader = false;
      this.serviceError = err.responseHead
        ? this.util.getIntlErrorMessage("MANAGE_ADMINS", err.responseHead.statusCode, err.responseHead.statusDesc)
        : err;
      this.util.simpleDialog({
        title: 'Error',
        message: this.serviceError,
      });
      this.route.navigate(['/admin/org-list']);
    });
  }

  updateOrgAdmin(event, id: number) {
    this.updateStatusService.updateStatus(event, id, false, 'updateOrgAdmin').subscribe((res: any) => {
      const responseHead = res.body.responseHead;
      const successMsg = responseHead ? this.util.getSuccessMessage('UPDATE_ADMIN_ROLE', responseHead.statusCode, responseHead.statusDesc) : responseHead;
      this.util.simpleDialog({
        title: 'Updated',
        message: successMsg,
      });
      this.route.navigate(['/admin/org-list']);
      this.util.showLoader = false;
    }, (err) => {
      this.util.showLoader = false;
      this.serviceError = err.responseHead
        ? this.util.getIntlErrorMessage("MANAGE_ADMINS", err.responseHead.statusCode, err.responseHead.statusDesc)
        : err;
      this.util.simpleDialog({
        title: 'Error',
        message: this.serviceError,
      });
      this.route.navigate(['/admin/org-list']);
    });
  }

  deleteAdmin(id: number) {
    const postData = {
      impactedRowId: id,
    };
    this.deleteService.delete(postData, 'deleteAdmin').subscribe((data: any) => {
      const responseHead = data.body.responseHead;
      const successMsg = responseHead ? this.util.getSuccessMessage('DELETE_ADMIN', responseHead.statusCode, responseHead.statusDesc) : responseHead;
      this.util.simpleDialog({
        title: 'Deleted',
        message: successMsg,
      });
      this.getAllAdmins();
      this.route.navigate(['/admin/org-list']);
      this.util.showLoader = false;
    }, (err) => {
      this.util.showLoader = false;
      this.serviceError = err.responseHead
        ? this.util.getIntlErrorMessage("MANAGE_ADMINS", err.responseHead.statusCode, err.responseHead.statusDesc)
        : err;
      this.util.simpleDialog({
        title: 'Error',
        message: this.serviceError,
      });
      this.route.navigate(['/admin/org-list']);
    });
  }

}