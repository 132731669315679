<ng-container>
  <h3 *ngIf="!hideTableTitle">{{ tableTitle }}</h3>

  <!-- Add New Icon -->
  <ng-container *ngIf="tableActions?.length > -1">
    <ng-container *ngFor="let tableAction of tableActions">
      <button
        class="{{ tableAction?.name }} float-right action-button tableActions "
        mat-mini-fab
        appAccessControl
        moduleType="{{ moduleType }}"
        pageType="{{ pageType }}"
        matTooltip="{{ tableAction?.iconTooltip }}"
        permissionName="{{ tableAction?.permission }}"
        (click)="tableActionsEmit(tableAction.name)"
      >
        <i class="fas fa-{{ tableAction?.iconName }} fa-2x"></i>
      </button>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isdatePicker && !isFilterPresent">
    <mat-form-field
      appearance="legacy"
      class="float-right"
      [ngClass]="{ 'col-lg-3': isdatePicker && isFilterable }"
    >
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="dateRangePicker" [formGroup]="range">
        <input
          matStartDate
          formControlName="start"
          placeholder="Start date"
          #dateRangeStart
          (dateChange)="filters()"
        />
        <input
          matEndDate
          placeholder="End date"
          formControlName="end"
          #dateRangeEnd
          (dateChange)="filters()"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle matPrefix [for]="dateRangePicker">
      </mat-datepicker-toggle>
      <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
    </mat-form-field>
  </ng-container>
  <!-- Filter -->
  <ng-container *ngIf="isFilterable && !isFilterPresent">
    <mat-form-field
      class="float-right"
      [ngClass]="{ 'col-lg-2': isdatePicker && isFilterable }"
    >
      <mat-label>Table Filter</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Type to Filter Any"
      />
    </mat-form-field>
  </ng-container>

  <div class="d-flex flex-wrap">
    <ng-container *ngIf="isFilterPresent">
      <div class="filter mr-2">
        <app-filter
          [filterData]="filterAttributes"
          (onSelect)="onSelect($event)"
          [filteredData]="selectedFilters"
          [noOfFilters]="keyLength(selectedFilters.filters)"
        ></app-filter>
      </div>
    </ng-container>
    <div class="filters mt-1 mb-1 d-flex flex-wrap">
      <div
        class="filter-item d-flex align-items-center mb-1 mr-1"
        *ngFor="let item of selectedFilters.filters | keyvalue; let i = index"
      >
        <div [innerHTML]="getFilter(item.key, item.value) | bold"></div>
        <span
          ><mat-icon (click)="clearFilterItem(item, i)" class="filter-close"
            >close</mat-icon
          ></span
        >
      </div>
    </div>
  </div>

  <!-- Table -->
  <table
    mat-table
    [dataSource]="tableDataSource"
    matSort
    (matSortChange)="sortTable($event)"
    matSortDisableClear
    matSortActive="S.No."
    matSortDirection="asc"
    matTableExporter
    #exporter="matTableExporter"
    [hiddenColumns]="hiddenColumns"
  >
    <!-- action column -->
    <ng-container
      *ngFor="let tableColumn of tableColumns; let indexOfelement = index"
      [matColumnDef]="tableColumn.name"
    >
      <!-- Checkbox Column -->
      <ng-container *ngIf="tableColumn?.select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="onClickCheckboxItem($event, row)"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container *ngIf="tableColumn?.singleSelect">
        <td mat-cell *matCellDef="let row">
          <mat-radio-button
            class="example-radio-button"
            (change)="radioButtonHandler(row)"
          >
          </mat-radio-button>
        </td>
      </ng-container>
      <!-- if sortable column header -->
      <ng-container *ngIf="tableColumn.isSortable; else notSortable">
        <th
          mat-header-cell
          *matHeaderCellDef
          [mat-sort-header]="tableColumn.name"
          [arrowPosition]="
            tableColumn.position === 'right' ? 'before' : 'after'
          "
        >
          {{ tableColumn.name }}
        </th>
      </ng-container>
      <!-- else not sortable -->
      <ng-template #notSortable>
        <th
          mat-header-cell
          *matHeaderCellDef
          [class.text-right]="tableColumn.position == 'right'"
        >
          {{ tableColumn.name }}
        </th>
      </ng-template>

      <ng-container [ngSwitch]="tableColumn.type">
        <ng-container *ngSwitchCase="'toogle'">
          <td
            mat-cell
            *matCellDef="let element"
            [class.text-right]="tableColumn.position == 'right'"
          >
            <div
              appAccessControl
              moduleType="{{ moduleType }}"
              pageType="{{ pageType }}"
              permissionName="{{ tableColumn?.permission }}"
            >
              <ng-container
                *ngIf="tableColumn?.conditionalNaming; else defaultTemplate"
              >
                <mat-slide-toggle
                  [checked]="
                    tableColumn?.conditionalNaming[
                      (element | dataPropertyGetter : tableColumn.dataKey)
                    ]?.checked
                  "
                  (change)="toogleEmitterClick($event, element)"
                  [disabled]="
                    tableColumn?.conditionalNaming[
                      (element | dataPropertyGetter : tableColumn.dataKey)
                    ]?.disabled
                  "
                  >{{
                    tableColumn?.conditionalNaming[
                      (element | dataPropertyGetter : tableColumn.dataKey)
                    ]?.name
                  }}</mat-slide-toggle
                >
              </ng-container>
              <ng-template #defaultTemplate>
                <mat-slide-toggle
                  [checked]="element | dataPropertyGetter : tableColumn.dataKey"
                  (change)="toogleEmitterClick($event, element)"
                  >Active</mat-slide-toggle
                >
              </ng-template>
            </div>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'actions'">
          <td mat-cell *matCellDef="let element">
            <ng-container *ngFor="let rowElement of tableColumn.rowActions">
              <ng-template
                *ngTemplateOutlet="
                  actionsTemplate;
                  context: { element: element, rowElement: rowElement }
                "
              ></ng-template>
            </ng-container>
          </td>
        </ng-container>
        <ng-conatiner *ngSwitchCase="'threeDotActions'">
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="menu icon"
              class="btn btn-secondary threeDotActions ml-2"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <ng-container *ngFor="let rowElement of tableColumn.rowActions">
                <ng-template
                  *ngTemplateOutlet="
                    actionsTemplate;
                    context: { element: element, rowElement: rowElement }
                  "
                ></ng-template>
              </ng-container>
            </mat-menu>
          </td>
        </ng-conatiner>
        <ng-container *ngSwitchCase="'alias'">
          <!-- column data -->
          <td
            mat-cell
            *matCellDef="let element"
            [class.text-right]="tableColumn.position == 'right'"
            [innerHTML]="
              element
                ? 'Enabled'
                : ('Disabled'
                  | dataPropertyGetter : tableColumn.dataKey
                  | highlightSearch : searchedString)
            "
          ></td>
        </ng-container>
        <ng-container *ngSwitchCase="'countButton'">
          <!-- column data -->
          <td mat-cell *matCellDef="let element">
            <a
              class="btn btn-success text-white"
              href=""
              [routerLink]="null"
              (click)="countButtonClick(tableColumn.name, element)"
              [innerHTML]="
                element
                  | dataPropertyGetter : tableColumn.dataKey
                  | highlightSearch : searchedString
              "
              >{{ element.customer_count }}
            </a>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'currencyCountButton'">
          <!-- column data -->
          <td mat-cell *matCellDef="let element">
            <a
              class="btn btn-success text-white"
              href=""
              [routerLink]="null"
              (click)="countButtonClick(tableColumn.name, element)"
              [innerHTML]="
                element
                  | dataPropertyGetter : tableColumn.dataKey
                  | currency : 'INR' : 'symbol-narrow'
                  | highlightSearch : searchedString
              "
              >{{ element.customer_count }}
            </a>
          </td>
        </ng-container>
        <!-- Date Filter On top of the results-->
        <ng-container *ngSwitchCase="'date'">
          <!-- column data -->
          <td
            mat-cell
            *matCellDef="let element"
            [class.text-right]="tableColumn.position == 'right'"
            [title]="element | dataPropertyGetter : tableColumn.dataKey"
            [innerHTML]="
              element
                | dataPropertyGetter : tableColumn.dataKey
                | date : 'dd-MMM-yyyy'
                | highlightSearch : searchedString
            "
          ></td>
        </ng-container>
        <!-- Currency Filter On top of the results-->
        <ng-container *ngSwitchCase="'currency'">
          <!-- column data -->
          <td
            mat-cell
            *matCellDef="let element"
            [class.text-right]="tableColumn.position == 'right'"
            [title]="element | dataPropertyGetter : tableColumn.dataKey"
            [innerHTML]="
              element
                | dataPropertyGetter : tableColumn.dataKey
                | currency : 'INR' : 'symbol-narrow'
                | highlightSearch : searchedString
            "
          ></td>
        </ng-container>
        <!--Table sequence for results-->
        <ng-container *ngSwitchCase="'sequence'">
          <td mat-cell *matCellDef="let element; let tableIndex = index">
            {{ tableIndex + 1 }}
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'eye'">
          <td mat-cell *matCellDef="let element; let tableIndex = index">
            <button
              mat-mini-fab
              matTooltip="View Details"
              (click)="countButtonClick(tableColumn.name, element)"
            >
              <mat-icon class="matclass">visibility</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'tags'">
          <td mat-cell *matCellDef="let element; let tableIndex = index">
            <mat-chip-listbox aria-label="td-tags">
              <div class="d-flex flex-row flex-wrap">
                <mat-chip
                  class="mr-2 my-1 py-1 px-2"
                  *ngFor="
                    let ele of (
                      element | dataPropertyGetter : tableColumn.dataKey
                    )?.slice(0, 2)
                  "
                >
                  {{ ele }}
                </mat-chip>
                <div
                  class="mt-2 show-all"
                  (click)="showAllTags(element[tableColumn.dataKey])"
                  *ngIf="
                    (element | dataPropertyGetter : tableColumn.dataKey)
                      .length >= 2
                  "
                >
                  Show all
                </div>
              </div>
            </mat-chip-listbox>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'attributes'">
          <td mat-cell *matCellDef="let element; let tableIndex = index">
            <mat-chip-listbox aria-label="td-tags">
              <div class="attributes-group">
                <mat-chip
                  class="attributes-chip"
                  *ngFor="
                    let ele of (
                      element | dataPropertyGetter : tableColumn.dataKey
                    )?.slice(0, 2)
                  "
                  [id]="ele.id"
                >
                  {{ ele.key + ":" + ele.value }}
                </mat-chip>
                <mat-chip
                  class="show-all"
                  (click)="showAllAttributes(element.custom_attrs)"
                >
                  Show all
                </mat-chip>
              </div>
            </mat-chip-listbox>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'custom_attr'">
          <td mat-cell *matCellDef="let element; let tableIndex = index">
            <mat-chip-listbox aria-label="td-tags">
              <div class="d-flex flex-row flex-wrap">
                <mat-chip
                  class="mr-2 my-1 py-1 px-2"
                  (click)="showAllAttributes(element.formattedTags)"
                  *ngFor="
                    let ele of (
                      element | dataPropertyGetter : tableColumn.dataKey
                    ).slice(0, 2)
                  "
                >
                  {{ ele }}
                </mat-chip>
                <div
                  class="mt-2 show-all"
                  (click)="showAllAttributes(element.formattedTags)"
                  *ngIf="
                    (element | dataPropertyGetter : tableColumn.dataKey)
                      .length > 2
                  "
                >
                  Show all
                </div>
              </div>
            </mat-chip-listbox>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'actionTags'">
          <td mat-cell *matCellDef="let element; let tableIndex = index">
            <mat-chip-listbox aria-label="td-tags">
              <div class="d-flex flex-row flex-wrap">
                <mat-chip
                  class="mr-2 my-1 py-1 px-2"
                  *ngFor="
                    let ele of (
                      element | dataPropertyGetter : tableColumn.dataKey
                    ).slice(0, 3)
                  "
                  (click)="emitActionTag(ele)"
                >
                  <mat-icon class="attachment"> attach_file</mat-icon>{{ ele }}
                </mat-chip>
                <div
                  class="mt-2 show-all"
                  (click)="showAllTags(element.attachments)"
                  *ngIf="
                    (element | dataPropertyGetter : tableColumn.dataKey)
                      .length >= 3
                  "
                >
                  Show all
                </div>
              </div>
            </mat-chip-listbox>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'rich_text'">
          <td
            mat-cell
            *matCellDef="let element; let tableIndex = index"
            [class.text-right]="tableColumn.position == 'right'"
            [title]="element | dataPropertyGetter : tableColumn.dataKey"
            (click)="openModal(element)"
            matTooltip="{{
              tableColumn?.additionalToolTip && element
                | dataPropertyGetter : tableColumn.additionalToolTip
            }}"
          >
            <ng-container
              *ngIf="
                (element | dataPropertyGetter : tableColumn.dataKey) ===
                NO_PREVIEW
              "
            >
              {{ NO_PREVIEW }}
              <mat-icon class="preview-icon">open_in_new</mat-icon>
            </ng-container>
            <ng-container
              *ngIf="
                (element | dataPropertyGetter : tableColumn.dataKey) !==
                NO_PREVIEW
              "
            >
              {{
                element
                  | dataPropertyGetter : tableColumn.dataKey
                  | highlightSearch : searchedString
                  | striphtml
              }}
            </ng-container>
          </td>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <!-- column data -->
          <td
            mat-cell
            *matCellDef="let element; let tableIndex = index"
            [class.text-right]="tableColumn.position == 'right'"
            [title]="element | dataPropertyGetter : tableColumn.dataKey"
            matTooltip="{{
              tableColumn?.additionalToolTip &&
                (element | dataPropertyGetter : tableColumn.dataKey) ===
                  'Others' &&
                element | dataPropertyGetter : tableColumn.additionalToolTip
            }}"
            [innerHTML]="
              tableColumn.dataKey == 'sno'
                ? matPaginator?.pageIndex * matPaginator?.pageSize +
                  tableIndex +
                  1
                : (element
                  | dataPropertyGetter : tableColumn.dataKey
                  | highlightSearch : searchedString)
            "
          ></td>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <!-- column data -->
          <td
            mat-cell
            *matCellDef="let element; let tableIndex = index"
            [class.text-right]="tableColumn.position == 'right'"
            [title]="element | dataPropertyGetter : tableColumn.dataKey"
            matTooltip="{{
              tableColumn?.additionalToolTip &&
                (element | dataPropertyGetter : tableColumn.dataKey) ===
                  'Others' &&
                element | dataPropertyGetter : tableColumn.additionalToolTip
            }}"
            [innerHTML]="
              tableColumn.dataKey == 'sno'
                ? matPaginator?.pageIndex * matPaginator?.pageSize +
                  tableIndex +
                  1
                : (element
                  | dataPropertyGetter : tableColumn.dataKey
                  | highlightSearch : searchedString)
            "
          ></td>
        </ng-container>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <ng-container *ngIf="!!searchedString">
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="3">
          No data matching the filter "{{ searchedString }}"
        </td>
      </tr>
    </ng-container>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell text-center" colspan="3">No records found!</td>
    </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <!-- Pagination -->
  <mat-paginator
    *ngIf="isPageable"
    [pageSizeOptions]="paginationSizes"
    [pageSize]="defaultPageSize"
    showFirstLastButtons
  >
  </mat-paginator>
</ng-container>

<!-- Template for Actions Button -->

<ng-template #actionsTemplate let-element="element" let-rowElement="rowElement">
  <button
    mat-button
    class="{{ rowElement?.name }} rowActions"
    mat-mini-fab
    appAccessControl
    moduleType="{{ moduleType }}"
    pageType="{{ pageType }}"
    permissionName="{{ rowElement?.permission }}"
    additionalRowPermission="{{ rowElement?.additionalRowPermission }}"
    conditionalOperator="{{ rowElement?.conditionalOperator }}"
    conditionalValue="{{ rowElement?.conditionalValue }}"
    [elementValue]="element"
    matTooltip="{{ rowElement?.iconTooltip }}"
    (click)="emitRowAction(rowElement?.name, element)"
  >
    <i class="fas fa-{{ rowElement?.iconName }} fa-2x"></i>
  </button>
</ng-template>

<!-- Show tags modal -->
<div
  class="modal fade"
  id="viewAllTagsPopup"
  role="dialog"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header text-center">
        <h3 id="hdrImageTitle" class="modal-title text">
          All {{ type | titlecase }}
        </h3>
        <button
          type="button"
          class="close float-right text"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="required">
                <div *ngIf="type === 'tags'">
                  <div
                    class="d-flex flex-row flex-wrap"
                    *ngIf="!isTableActionTagPresent; else actionTags"
                  >
                    <mat-chip
                      class="mx-2 my-1 py-1 px-2"
                      *ngFor="let tag of allTags"
                    >
                      {{ tag }}
                    </mat-chip>
                  </div>
                  <ng-template class="d-flex flex-row flex-wrap" #actionTags>
                    <mat-chip
                      class="mx-2 my-1 py-1 px-2"
                      *ngFor="let tag of allTags"
                      (click)="emitActionTag(tag)"
                    >
                      {{ tag }}
                    </mat-chip>
                  </ng-template>
                </div>
                <div *ngIf="type !== 'tags'">
                  <div>
                    <div
                      class="mx-2 my-1 py-1 px-2"
                      *ngFor="let tag of allTags"
                    >
                      <strong>{{ tag.key }} : {{ tag.value }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Show attributes modal -->
<div
  class="modal fade"
  id="viewAllAttributesPopup"
  role="dialog"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header text-center">
        <h3 id="hdrImageTitle" class="modal-title text">All Attributes</h3>
        <button
          type="button"
          class="close float-right text"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="required">
                <div>
                  <div class="d-flex flex-row flex-wrap">
                    <mat-chip
                      class="mx-2 my-1 py-1 px-2 attributes-chip"
                      *ngFor="let attribute of allAttributes"
                    >
                      {{ attribute.key + ":" + attribute.value }}
                    </mat-chip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
