import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Actions, TableColumn } from './TableColumn';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableExporterDirective } from 'mat-table-exporter';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ExportService } from '@app/exportservice/export.service';
import { UpdateStatusService } from '@app/shared/table/updateStatus.service';
import { DownloadService } from '../services/download.service';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import {
  ATTRIBUTES,
  NO_PREVIEW,
  TAGS,
} from '../constants/application-constants';
import { UploadComponent } from '../components/upload/upload.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NonedittextboxComponent } from '../components/nonedittextbox/nonedittextbox.component';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
declare var $: any;

export interface dateRangeElement {
  isSingle: boolean;
  startDataKey: string;
  endDataKey: string;
}

export enum constants {
  'LIST' = 'list',
  'DATE' = 'date',
  'BETWEEN' = 'between',
  'ALL' = 'all',
  'TEXT' = 'text',
  'NUMBER' = 'number',
  'CONTAINS' = 'contains',
  'IS' = 'is',
}

@Component({
  selector: 'custom-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit {
  public tableDataSource = new MatTableDataSource([]);
  public selection = new SelectionModel(true, []);
  public displayedColumns: string[];
  private originalTableData: Array<any>;
  public searchedString: string = '';
  NO_PREVIEW = NO_PREVIEW;
  readonly constants: typeof constants = constants;
  range = new FormGroup({
    start: new FormControl<Date | null>(new Date()),
    end: new FormControl<Date | null>(null),
  });
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 500,
  });
  defaultTreeViewItem = {
    text: 'Select options',
    value: '',
    checked: false,
    disabled: true,
  };
  buttonElement: any;

  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  @ViewChild(MatTableExporterDirective, { static: false })
  exporter: MatTableExporterDirective;
  @Input() isPageable = true;
  @Input() isdatePicker = false;
  @Input() isFilterPresent = false;
  @Input() dateDateKey: string;
  @Input() isSortable = true;
  @Input() isFilterable = true;
  @Input() tableColumns: TableColumn[] = [];
  @Input() tableActions: Array<Actions> = [];
  @Input() paginationSizes: number[] = [10, 20, 50, 100];
  @Input() defaultPageSize = this.paginationSizes[0];
  @Input() rowActions: Array<Actions> = [];
  @Input() tableTitle: string;
  @Input() filterAttributes: Array<any>;
  @Input() radioButtonCallBack: Function;
  @Input() selectedItems: any;
  // hidden Columns Array will be hidden from the downloaded excel sheet
  @Input() hiddenColumns: Array<number>;
  // moduleType - Required for Permission Check
  @Input() moduleType: string;
  // pageType - Required for Permission Check
  @Input() pageType: string;
  @Output() sort: EventEmitter<Sort> = new EventEmitter();
  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() toogleEmitter: EventEmitter<any> = new EventEmitter<any>(); // Emit Events of toogle Actions
  @Output() tableActionEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() tableDateRangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() dateRangeDetails: dateRangeElement;
  @Output() tableActionTag: EventEmitter<any> = new EventEmitter<any>();
  @Input() isTableActionTagPresent = false;
  // this property needs to have a setter, to dynamically get changes from parent component
  @Output() countButtonEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() set tableData(data: any[]) {
    this.setTableDataSource(data);
  }
  @Input() hideTableTitle: boolean = false;
  @Output() selectedItemsEmitter = new EventEmitter<any[]>();
  allTags: any;
  allAttributes: any;
  selectedFilters = {
    filters: {},
  };
  type: any;
  private dialogRef: MatDialogRef<NonedittextboxComponent> | null = null;

  constructor(
    private exportService: ExportService,
    private updateStatusService: UpdateStatusService,
    private downloadService: DownloadService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const columnNames = this.tableColumns.map(
      (tableColumn: TableColumn) => tableColumn.name
    );
    this.displayedColumns = columnNames;
    const startDate = new Date(new Date().setDate(new Date().getDate() - 30));
    const endDate = new Date();
    this.range = new FormGroup({
      start: new FormControl(startDate),
      end: new FormControl(endDate),
    });
    const filter = this.filterAttributes?.find(
      (item: any) => item.type === 'date'
    );
    if (filter) {
      this.selectedFilters.filters = {
        date: {
          datakey: filter.dataKeyName,
          name: filter.name,
          option: constants.BETWEEN,
          type: constants.DATE,
          start: startDate,
          end: endDate,
        },
      };
    }
    this.filters();
  }

  // we need this, in order to make pagination work with *ngIf
  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.matPaginator;
  }

  keyLength = function (obj) {
    return Object.keys(obj).length;
  };

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.tableDataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  setTableDataSource(data: any) {
    this.originalTableData = data;
    this.tableDataSource = new MatTableDataSource<any>(data);
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.matSort;
  }
  countButtonClick(name: any, row: any) {
    this.countButtonEmitter.emit({ name: name, row: row });
  }
  applyFilter(event: any, type?: string) {
    let filterValue;
    if (type) {
      filterValue = event;
    } else {
      filterValue = (event.target as HTMLInputElement).value;
    }
    this.searchedString = filterValue.trim(); // Searched String is used for highlight
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  sortTable(sortParameters: Sort) {
    // defining name of data property, to sort by, instead of column name
    sortParameters.active = this.tableColumns.find(
      (column) => column.name === sortParameters.active
    ).dataKey;
    this.sort.emit(sortParameters);
    const sortedData = this.sortData(sortParameters);
    this.setTableDataSource(sortedData);
  }

  emitRowAction(rowName: any, row: any) {
    this.rowAction.emit({ rowName: rowName, row: row });
  }

  emitActionTag(tag: any) {
    this.tableActionTag.emit({ tag });
  }

  convertDate() {
    var day;
    var month;
    var date = new Date();
    day = date.getDate();
    month = date.getMonth();
    month = month + 1;
    if (String(day).length == 1) day = '0' + day;
    if (String(month).length == 1) month = '0' + month;
    return date.getFullYear() + '' + month + '' + day;
  }

  sortData(sortParameters: Sort) {
    const keyName = sortParameters.active;
    if (sortParameters.direction === 'asc') {
      return this.originalTableData.sort((a: any, b: any) => {
        if (typeof a[keyName] == 'number') return a[keyName] - b[keyName];
        if (typeof a[keyName] == 'string')
          return a[keyName].localeCompare(b[keyName]);
      });
    } else if (sortParameters.direction === 'desc') {
      return this.originalTableData.sort((a: any, b: any) => {
        if (typeof b[keyName] == 'number') return b[keyName] - a[keyName];
        if (typeof b[keyName] == 'string')
          return b[keyName].localeCompare(a[keyName]);
      });
    }
  }

  /* Toogle Emitter Value*/
  toogleEmitterClick(event: any, row: any) {
    if (row?.id) {
      try {
        this.updateStatusService.updateStatus(event, row?.id);
      } catch {
        this.toogleEmitter.emit({
          checked: event.checked,
          id: row?.id,
          row: row,
        });
      }
    } else {
      try {
        this.updateStatusService.updateStatus(event, row);
      } catch {
        this.toogleEmitter.emit({ checked: event.checked, row: row });
      }
    }
  }

  onClickCheckboxItem(event, row) {
    const selected = this.selection.isSelected(row);
    if (!selected) {
      this.selectedItems?.push(row);
      this.selection.select(row);
    } else {
      const index = this.selectedItems.findIndex(
        (item: any) => item.id === row.id
      );
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      }
      this.selection.deselect(row);
    }
  }
  /* Table Actions Emitter Value */
  tableActionsEmit(event: any) {
    if (event == 'inlineDownload') {
      this.selection?.selected?.length == 0 ||
      this.originalTableData.length == this.selection?.selected?.length
        ? this.exporter.exportTable('xlsx', {
            fileName: `${
              this.tableTitle
            }_${this.convertDate()}_${new Date().getTime()}`,
            sheet: 'Data',
          })
        : this.exportService.exportExcel(
            this.selection.selected,
            `${this.tableTitle}_${this.convertDate()}_${new Date().getTime()}`
          );
    }

    if (event == 'sampleFileDownload') {
      const type = this.tableActions.find(
        (item) => item.name === event
      )?.sampleOf;
      if (type) {
        this.downloadService.downloadSampleFile(type).subscribe((data: any) => {
          let binaryData = [];
          binaryData.push(data.body);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: 'blob' })
          );
          downloadLink.setAttribute(
            'download',
            `${
              this.tableTitle ? this.tableTitle.replace(/\s/g, '') : type
            }_sample.xlsx`
          );
          document.body.appendChild(downloadLink);
          downloadLink.click();
        });
      }
    } else {
      this.tableActionEmitter.emit(event);
    }
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'buffer',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  showAllTags(elements: any) {
    this.allTags = elements;
    this.type = TAGS;
    $('#viewAllTagsPopup').modal('show');
  }

  showAllAttributes(elements: any) {
    this.allTags = elements;
    this.type = ATTRIBUTES;
    $('#viewAllTagsPopup').modal('show');
  }

  radioButtonHandler(row) {
    if (this.radioButtonCallBack) this.radioButtonCallBack(row);
  }

  dateRangeChange(allData) {
    const dateRangeStart = this.range.value.start;
    const dateRangeEnd = this.range.value.end;

    if (!dateRangeStart && !dateRangeEnd) this.tableDateRangeEmitter.emit();
    if (this.isdatePicker) {
      if (!!this.tableDateRangeEmitter) {
        const data = {
          start: dateRangeStart,
          end: dateRangeEnd,
        };
        this.tableDateRangeEmitter.emit(data);
      } else {
        let newData;
        var from = new Date(dateRangeStart);
        if (!!dateRangeEnd) {
          const to = new Date(dateRangeEnd);
          newData = allData?.filter((item) => {
            if (this.dateRangeDetails?.isSingle) {
              const check = new Date(
                item?.[this.dateRangeDetails?.startDataKey || constants.DATE]
              );
              return check >= from && check <= to;
            } else {
              const itemStart = new Date(
                item?.[this.dateRangeDetails?.startDataKey]
              );
              const itemEnd = new Date(item?.[this.dateRangeDetails?.endDataKey]);
              return (
                itemStart >= from &&
                itemStart <= to &&
                itemEnd >= from &&
                itemEnd <= to
              );
            }
          });
        } else {
          newData = allData?.filter((item) => {
            if (this.dateRangeDetails?.isSingle) {
              const check = moment(
                item?.[this.dateDateKey || constants.DATE]
              ).format('YYYY-MM-DD');
              const fromDate = moment(from).format('YYYY-MM-DD');
              return check == fromDate;
            } else {
              const itemStart = new Date(
                item?.[this.dateRangeDetails?.startDataKey]
              );
              const itemEnd = new Date(
                item?.[this.dateRangeDetails?.endDataKey]
              );
              return from >= itemStart && from <= itemEnd;
            }
          });
        }
        return newData;
      }
    }
    return allData;
  }

  onFilterSelect(allData) {
    if (this.isFilterPresent) {
      const selectedFilters = this.selectedFilters?.filters;
      let output;
      const filteredData = allData?.filter((element) => {
        let allElementsPresent = true;
        for (let key in selectedFilters) {
          if (key === constants.ALL || key === constants.DATE)
            allElementsPresent = true;
          else {
            if (Array.isArray(element[key])) {
              const isTagPresent = selectedFilters[key].tags[
                selectedFilters[key].datakey
              ].find((tag) => element[key].includes(tag));
              if (!isTagPresent && selectedFilters[key].length > 0)
                allElementsPresent = false;
            } else {
              if (selectedFilters[key].option === constants.IS) {
                if (element[key] !== selectedFilters[key].value)
                  allElementsPresent = false;
              } else if (selectedFilters[key].option === constants.CONTAINS) {
                if (
                  !element[key]
                    .toLowerCase()
                    .includes(selectedFilters[key].value.toLowerCase())
                )
                  allElementsPresent = false;
              } else if (selectedFilters[key].option === constants.BETWEEN) {
                if (
                  !(
                    element[key] > selectedFilters[key].start &&
                    element[key] < selectedFilters[key].end
                  )
                )
                  allElementsPresent = false;
              } else {
                if (
                  !selectedFilters[key].tags[
                    selectedFilters[key].datakey
                  ]?.includes(element[key])
                )
                  allElementsPresent = false;
              }
            }
          }
        }
        return allElementsPresent;
      });
      output = filteredData;
      return output;
    }
    // return allData;
  }

  filters() {
    const dateResult = this.dateRangeChange(this.originalTableData);
    const filterResult = this.onFilterSelect(dateResult);
    this.tableDataSource = new MatTableDataSource<any>(filterResult);
  }

  onSelect(filters: any) {
    switch (filters.type) {
      case constants.ALL: {
        this.selectedFilters.filters[constants.ALL] = { ...filters };
        this.applyFilter(filters.value, filters.type);
        break;
      }
      case constants.DATE: {
        this.selectedFilters.filters[constants.DATE] = { ...filters };
        if (filters.option === constants.IS) {
          this.range = new FormGroup({
            start: new FormControl(filters.start),
            end: new FormControl(filters.end),
          });
          this.filters();
        } else {
          this.range = new FormGroup({
            start: new FormControl(filters.start),
            end: new FormControl(filters.end),
          });
          this.filters();
        }
        break;
      }
      case constants.TEXT: {
        this.selectedFilters.filters[filters.datakey] = { ...filters };
        this.filters();
        break;
      }
      case constants.NUMBER: {
        this.selectedFilters.filters[filters.datakey] = { ...filters };
        this.filters();
        break;
      }
      case constants.LIST: {
        if (filters.tags?.[filters.datakey]?.length) {
          this.selectedFilters.filters[filters.datakey] = { ...filters };
          this.filters();
        }
        break;
      }
    }
  }

  getFilter(itemKey, itemValue) {
    if (itemValue.type === constants.LIST) {
      return `* ${itemValue.name} * includes * [${itemValue.tags[itemKey]}] *`;
    }
    if (
      itemValue.type === constants.TEXT ||
      itemValue.type === constants.NUMBER
    ) {
      if (itemValue.option === constants.IS) {
        return `* ${itemValue.name} * is * ${itemValue.value} *`;
      }
      if (itemValue.option === constants.CONTAINS) {
        return `* ${itemValue.name} * contains * ${itemValue.value} *`;
      }
      if (itemValue.option === constants.BETWEEN) {
        return `* ${itemValue.name} * is between * ${itemValue.start} * and * ${itemValue.end} *`;
      }
    }
    if (itemValue.type === constants.DATE) {
      if (itemValue.option === constants.IS) {
        return `* ${itemValue.name} * is * ${moment(itemValue.start).format(
          'DD-MMM-YYYY'
        )} *`;
      }
      if (itemValue.option === constants.BETWEEN) {
        return `* ${itemValue.name} * is between * ${moment(
          itemValue.start
        ).format('DD-MMM-YYYY')} * and * ${moment(itemValue.end).format(
          'DD-MMM-YYYY'
        )} *`;
      }
    }
    if (itemValue.type === constants.ALL) {
      return `search: * ${itemValue.value} *`;
    }
  }

  clearFilterItem(item) {
    if (item.key === constants.ALL) {
      delete this.selectedFilters.filters[item.key];
      this.applyFilter('', constants.ALL);
    } else if (item.key === constants.DATE) {
      delete this.selectedFilters.filters[item.key];
      this.range = new FormGroup({
        start: new FormControl<Date | null>(null),
        end: new FormControl<Date | null>(null),
      });
      this.filters();
    } else {
      delete this.selectedFilters.filters[item.key];
      this.filters();
    }
  }

  openModal(data: any) {
    if (data.processed_question_text === NO_PREVIEW) {
      this.dialogRef = this.dialog.open(NonedittextboxComponent, {
        width: '1000px', // Set the modal width as per your requirements
        data: {text: data.question_text, title: 'Question Preview'}, // You can pass data to your modal if needed
      });

      // Handle modal close or dismiss actions if necessary
      this.dialogRef.afterClosed().subscribe(result => {
        // Handle modal close actions here if needed
        this.dialogRef = null; // Clear the reference to the dialog
      });
    }
  }

  closeModal() {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null; // Clear the reference to the dialog
    }
  }
}
