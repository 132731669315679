import { ChangeDetectionStrategy, Component, OnInit,ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

export interface PeriodicElement {
}
const ELEMENT_DATA: PeriodicElement[] = [
  { Vertical: 'Corporate', Allocated: 2510, Scheduled: 85, Assessed: 421, Invoiced:116,Cancelled: 30},
  { Vertical: 'LabourNet', Allocated: 2510, Scheduled: 85, Assessed: 421, Invoiced:116,Cancelled: 30},
  { Vertical: 'Sambhav', Allocated: 2510, Scheduled: 85, Assessed: 421, Invoiced:116,Cancelled: 30},
  { Vertical: 'Navriti Internal', Allocated: 2510, Scheduled: 85, Assessed: 421, Invoiced:116,Cancelled: 30},
  { Vertical: 'Construction', Allocated: 2510, Scheduled: 85, Assessed: 421, Invoiced:116,Cancelled: 30},
  { Vertical: 'Telecom', Allocated: 2510, Scheduled: 85, Assessed: 421, Invoiced:116,Cancelled: 30},
  { Vertical: 'Retail', Allocated: 2510, Scheduled: 85, Assessed: 421, Invoiced:116,Cancelled: 30},
  { Vertical: 'BFSI', Allocated: 2510, Scheduled: 85, Assessed: 421, Invoiced:116,Cancelled: 30},
  { Vertical: 'Apparel', Allocated: 2510, Scheduled: 85, Assessed: 421, Invoiced:116,Cancelled: 30},
];


 @Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  
sidebarOpen = false
pageSizeOptions = [];

displayedColumns: string[] = ['position', 'name', 'weight', 'symbol','a','b'];
dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor() { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    
  }

  sideBarToggler(){
    this.sidebarOpen = !this.sidebarOpen;
  }

  

}
