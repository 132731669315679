<!--------------------------------------------Top header------------------------------------------------------>
<mat-progress-bar mode="indeterminate" *ngIf="false"></mat-progress-bar>
<!--------------------------------------------------Navbar-------------------------------------->
<div class="sticky-top navbar-header border-bottom border-success">
  <div class="header-logo-and-menu-block">
    <div class="header-logo">
      <a class="header-brand" [routerLink]="['/']">
        <div *ngIf="isAdmin; else organisationLogo">
          <div class="org-logo">
            <img
              src="assets/images/new-logo.png"
              height="35"
              alt="Missing Org Logo"
            />
          </div>
        </div>

        <ng-template #organisationLogo>
          <div class="org-logo">
            <img
              [src]="orgLogo"
              width="120"
              height="35"
              alt="Missing Org Logo"
            />
          </div>
        </ng-template>
      </a>
    </div>
    <div class="header-menu-block hor-menu">
      <div class="horizontalMenu" #headerMenuList>
        <ul class="horizontalMenu-list" *ngIf="isAutoMenuON">
          <li aria-haspopup="true" *ngFor="let m of listOfVisibleMenus">
            <a
              [routerLink]="[m.url ? m.url : '']"
              class="header-menu-item"
              style="color: black"
            >
              {{ m.menu_name }}
              <i
                *ngIf="m.pages && m.pages.length"
                class="fe fe-chevron-down horizontal-icon"
              ></i>
            </a>
            <ul class="sub-menu" *ngIf="m.pages && m.pages.length">
              <li
                aria-haspopup="true"
                [ngClass]="s.url ? '' : 'sub-menu-sub'"
                *ngFor="let s of m.pages"
              >
                <a [routerLink]="[s.url ? s.url : '']" class="slide-item">
                  {{ getMenuLabelName(s) }}
                  <i
                    *ngIf="s.pages && s.pages.length"
                    class="fe fe-chevron-right horizontal-icon"
                  ></i>
                </a>
                <ul class="sub-menu" *ngIf="s.pages && s.pages.length">
                  <li aria-haspopup="true" *ngFor="let t of s.pages">
                    <a [routerLink]="[t.url ? t.url : '']" class="slide-item">{{
                      t.menu_name
                    }}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li aria-haspopup="true" id="headerMenuItems" class="more-block" *ngIf="listOfNonVisibleMenus?.length > 0">
            <div class="more">
              More
              <i class="fe fe-chevron-down horizontal-icon"></i>
            </div>
            <ul class="sub-menu more-sub-menu">
              <li aria-haspopup="true" *ngFor="let m of listOfNonVisibleMenus">
                <a
                  [routerLink]="[m.url ? m.url : '']"
                  class="header-menu-item"
                  style="color: black"
                >
                  {{ m.menu_name }}
                  <i
                    *ngIf="m.pages && m.pages.length"
                    class="fe fe-chevron-right horizontal-icon"
                  ></i>
                </a>
                <ul class="sub-menu" *ngIf="m.pages && m.pages.length">
                  <li
                    aria-haspopup="true"
                    [ngClass]="s.url ? '' : 'sub-menu-sub'"
                    *ngFor="let s of m.pages"
                  >
                    <a [routerLink]="[s.url ? s.url : '']" class="slide-item">
                      {{ getMenuLabelName(s) }}
                      <i
                        *ngIf="s.pages && s.pages.length"
                        class="fe fe-chevron-right horizontal-icon"
                      ></i>
                    </a>
                    <ul class="sub-menu" *ngIf="s.pages && s.pages.length">
                      <li aria-haspopup="true" *ngFor="let t of s.pages">
                        <a
                          [routerLink]="[t.url ? t.url : '']"
                          class="slide-item"
                          >{{ t.menu_name }}</a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="horizontalMenu-list" *ngIf="!isAutoMenuON">
          <li aria-haspopup="true">
            <a
              href="#"
              [routerLink]="['/Dashboard']"
              class=""
              style="color: white"
              >Dashboard
            </a>
          </li>
          <li aria-haspopup="true">
            <a href="#" [routerLink]="['/Sales/Leads']" style="color: white"
              >Sales<i class="fe fe-chevron-down horizontal-icon"></i
            ></a>
            <ul class="sub-menu">
              <li aria-haspopup="true">
                <a href="#" [routerLink]="['/Sales/Leads']" class="slide-item"
                  >Leads</a
                >
              </li>
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Sales/Opportunities']"
                  class="slide-item"
                  >Opportunities</a
                >
              </li>
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Sales/Customer']"
                  class="slide-item"
                  >Customers</a
                >
              </li>
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Sales/CustomerGroup']"
                  class="slide-item"
                  >Customer Groups</a
                >
              </li>
              <li aria-haspopup="true">
                <a href="#" [routerLink]="['/Sales/Scheme']" class="slide-item"
                  >Schemes</a
                >
              </li>
              <li aria-haspopup="true">
                <a href="#" [routerLink]="null" class="slide-item">Analysis</a>
              </li>
            </ul>
          </li>

          <li aria-haspopup="true">
            <a href="#" [routerLink]="['/Content/Courses']" style="color: white"
              >Content<i class="fe fe-chevron-down horizontal-icon"></i
            ></a>
            <ul class="sub-menu">
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Content/Courses']"
                  class="slide-item"
                  >Courses</a
                >
              </li>
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Content/QuestionBank']"
                  class="slide-item"
                  >Question Banks</a
                >
              </li>
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Content/QuestionPapers']"
                  class="slide-item"
                  >Question Paper</a
                >
              </li>
              <li aria-haspopup="true">
                <a href="#" [routerLink]="null" class="slide-item">Analysis</a>
              </li>
            </ul>
          </li>

          <li aria-haspopup="true">
            <a href="#" style="color: white"
              >Operations<i class="fe fe-chevron-down horizontal-icon"></i
            ></a>
            <ul class="sub-menu">
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Operations/Candidate']"
                  class="slide-item"
                  >Candidates</a
                >
              </li>
              <li aria-haspopup="true" class="sub-menu-sub">
                <a href="#" class="slide-item">Assessments</a>
                <ul class="sub-menu">
                  <li aria-haspopup="true">
                    <a
                      href="#"
                      [routerLink]="['/Operations/AssessmentPlan']"
                      class="slide-item"
                      >Plan</a
                    >
                  </li>
                  <li aria-haspopup="true">
                    <a href="#" class="slide-item">Execute</a>
                  </li>
                  <li aria-haspopup="true">
                    <a href="#" class="slide-item">Evaluate</a>
                  </li>
                </ul>
              </li>
              <li aria-haspopup="true">
                <a href="#" class="slide-item">Analysis</a>
              </li>
            </ul>
          </li>

          <li aria-haspopup="true">
            <a
              href="#"
              [routerLink]="['/Resources/Roles']"
              class=""
              style="color: white"
              >Resources<i class="fe fe-chevron-down horizontal-icon"></i
            ></a>
            <ul class="sub-menu">
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Resources/Roles']"
                  class="slide-item"
                  >Roles</a
                >
              </li>
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Resources/UserCategory']"
                  class="slide-item"
                  >User Category</a
                >
              </li>
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Resources/Users']"
                  class="slide-item"
                  >Users</a
                >
              </li>
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Resources/Assets']"
                  class="slide-item"
                  >Assets</a
                >
              </li>
              <li aria-haspopup="true">
                <a href="#" class="slide-item">Analysis</a>
              </li>
            </ul>
          </li>

          <li aria-haspopup="true">
            <a href="#" style="color: white"
              >Finance<i class="fe fe-chevron-down horizontal-icon"></i
            ></a>
            <ul class="sub-menu">
              <li aria-haspopup="true">
                <a
                  href="#"
                  class="slide-item"
                  [routerLink]="['/Finance/RateMaster']"
                  >Rate Master</a
                >
              </li>
              <li aria-haspopup="true">
                <a
                  href="#"
                  class="slide-item"
                  [routerLink]="['/Finance/Invoices']"
                  >Invoices</a
                >
              </li>
              <li aria-haspopup="true">
                <a href="#" class="slide-item" [routerLink]="['/Finance/Bills']"
                  >Bills</a
                >
              </li>
              <li aria-haspopup="true">
                <a
                  href="#"
                  class="slide-item"
                  [routerLink]="['/Finance/Receipt']"
                  >Receipts</a
                >
              </li>
              <li aria-haspopup="true">
                <a
                  href="#"
                  class="slide-item"
                  [routerLink]="['/Finance/Contracts']"
                  >Contracts</a
                >
              </li>
              <li aria-haspopup="true">
                <a href="#" class="slide-item">Analysis</a>
              </li>
            </ul>
          </li>

          <li aria-haspopup="true">
            <a href="#" style="color: white"
              >Logistics<i class="fe fe-chevron-down horizontal-icon"></i
            ></a>
            <ul class="sub-menu">
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Logistics/Travel/TravelRequest']"
                  class="slide-item"
                  >Travel</a
                >
              </li>
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Logistics/Travel/AccomodationRequest']"
                  class="slide-item"
                  >Accommodation</a
                >
              </li>
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Logistics/Travel/Courier']"
                  class="slide-item"
                  >Courier</a
                >
              </li>
              <li aria-haspopup="true">
                <a href="#" class="slide-item">Analysis</a>
              </li>
            </ul>
          </li>
          <!-- Please remove this extra copy for test cases -->
          <li aria-haspopup="true">
            <a href="#" style="color: white"
              >Logistics<i class="fe fe-chevron-down horizontal-icon"></i
            ></a>
            <ul class="sub-menu">
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Logistics/Travel/TravelRequest']"
                  class="slide-item"
                  >Travel</a
                >
              </li>
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Logistics/Travel/AccomodationRequest']"
                  class="slide-item"
                  >Accommodation</a
                >
              </li>
              <li aria-haspopup="true">
                <a
                  href="#"
                  [routerLink]="['/Logistics/Travel/Courier']"
                  class="slide-item"
                  >Courier</a
                >
              </li>
              <li aria-haspopup="true">
                <a href="#" class="slide-item">Analysis</a>
              </li>
            </ul>
          </li>

          <!-- <li aria-haspopup="true"><a href="#" style="color: white;">Compliance<i class="fe fe-chevron-down horizontal-icon"></i></a>
            <ul class="sub-menu">
                <li aria-haspopup="true"><a href="#" class="slide-item">ISO trackers</a></li>
                <li aria-haspopup="true"><a href="#" class="slide-item">Customer</a></li>
                <li aria-haspopup="true"><a href="#" class="slide-item">Analytics</a></li>
            </ul>
        </li> -->
        </ul>
      </div>
    </div>
  </div>
  <div class="general-accessory-block">
    <div
      class="main-header-search ml-3 d-sm-none d-md-none d-lg-block search-block"
    >
      <button class="btn" [matMenuTriggerFor]="search?.menu">
        <i style="font-size: 20px; color: #404259" class="fa">&#xf002;</i>
      </button>

      <div class="input-group mb-3">
        <ng-container>
          <app-globalsearch #search="globalSearchComponent"></app-globalsearch>
        </ng-container>
      </div>
    </div>
    <div
      class="main-header-message right-toggle notifications-icon notification-bell-block"
      (click)="open()"
      [matMenuTriggerFor]="msg.menu"
    >
      <span class="fas fa-bell"></span>
      <span class="notification-box" *ngIf="notificationCount > 0">{{
        notificationCount
      }}</span>
      <app-notifications
        [(count)]="notificationCount"
        #msg="menuNotificationComponent"
      ></app-notifications>
    
    </div>
    <div
      class="main-header-message settings-icon settings-block"
    >
      <div class="nav nav-item navbar-nav-right ml-auto">
        <div class="dropdown main-profile-menu nav nav-item nav-link">
          <div
            class=""
            appClickOutside
            (click)="showSettingsDropdown()"
            (clickOutside)="showSettings = false"
          >
            <a
              class="settings-icon-link"
              href="javascript:void(0);"
              [routerLink]="null"
            >
              <i class="fas fa-cog fa-lg settings-icon-class"></i>
            </a>
          </div>

          <div
            id="settingsDropdown"
            class="dropdown-menu dropdown-menu-arrow animated fadeInUp"
            [ngClass]="{ showDropDown: showSettings }"
          >
            <a class="dropdown-item" [routerLink]="['/admin']" *ngIf="isAdmin || isOrgSuperAdmin">
              <i class="fas fa-user-shield"></i>Org Settings
            </a>
            <a
              class="dropdown-item"
              [routerLink]="['/settings/notification-settings']"
              ><i class="fa fa-sliders-h" aria-hidden="true"></i>Notification
              Settings</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="nav nav-item profile">
      <div class="dropdown main-profile-menu nav nav-item nav-link">
        <div class="avatar-circle" (click)="ShowDropdown()">
          <a class="profile-user" href="javascript:void(0);">
            <span class="initials">{{ printInitial() }}</span>
          </a>
        </div>
        <div
          id="dropdown"
          class="dropdown-menu dropdown-menu-arrow animated fadeInUp"
        >
          <div class="main-header-profile header-img">
            <h4>{{ username }}</h4>
            <span>{{ loginUserRole }}</span>
            <span>{{ useremail }}</span>
          </div>
          <div
            class="main-header-profile header-img"
            *ngIf="userOrgList && userOrgList.length"
          >
            <div *ngIf="userOrgList && userOrgList.length > 1">
              <select
                class="form-control org-list"
                [(ngModel)]="selectedUserOrg"
                (change)="handleOrgChange($event)"
              >
                <option
                  *ngFor="let list of userOrgList"
                  class="form-control"
                  [value]="list.org_id"
                >
                  {{ list.org_name }}
                </option>
              </select>
            </div>
            <div
              *ngIf="userOrgList && userOrgList.length == 1"
              class="org-name"
            >
              {{ userOrgList[0].org_name }}
            </div>
          </div>

          <a style="display: none" class="dropdown-item" href="#"
            ><i class="far fa-edit"></i>Edit profile</a
          >

          <a class="dropdown-item" [routerLink]="['/account/change-password']"
            ><i class="fas fa-sliders-h"></i>Change password</a
          >
          <a class="dropdown-item" (click)="logout()" href="javascript:void(0)"
            ><i class="fas fa-sign-out-alt"></i> Sign Out</a
          >
        </div>
      </div>
    </div>
    <!----------------------------------Help Desk---------------------------------------->
    <div class="nav nav-item navbar-nav-right question">
      <div class="dropdown main-profile-menu nav nav-item nav-link">
        <div
          class="avatar-circle"
          appClickOutside
          (click)="ShowHelpDeskDropdown()"
          (clickOutside)="clickOutside()"
        >
          <a
            class="profile-user"
            href="javascript:void(0);"
            [routerLink]="null"
          >
            <i
              style="margin-top: 6px; font-size: 25px; color: white"
              class="fa fa-question"
              aria-hidden="true"
            ></i>
          </a>
        </div>

        <div
          id="helpdeskDropdown"
          class="dropdown-menu dropdown-menu-arrow animated fadeInUp"
          [ngClass]="{ showDropDown: showHelpDesk }"
        >
          <a style="display: none" class="dropdown-item" href="#"
            ><i class="far fa-edit"></i>Edit profile</a
          >
          <a class="dropdown-item" [routerLink]="['/ChangePassword']"
            ><i class="fa fa-ticket" aria-hidden="true"></i>Ticket</a
          >
          <a class="dropdown-item" href="" [routerLink]="null"
            ><i class="fa fa-phone" aria-hidden="true"></i>Support</a
          >
          <a class="dropdown-item" href="" [routerLink]="['/Tutorials']"
            ><i class="fa fa-file-text" aria-hidden="true"></i>Tutorial</a
          >
          <a
            class="dropdown-item"
            href="{{ developerUrl }}"
            *ngIf="isAdmin || isOrgAdmin || loginUserRole == SUPER_ADMIN"
            target="_blank"
            ><i class="fas fa-laptop-code" aria-hidden="true"></i>API Console</a
          >
          <a class="dropdown-item" href="" [routerLink]="null"
            ><i class="fa fa-list" aria-hidden="true"></i>Policies</a
          >
          <a class="dropdown-item" href="" [routerLink]="null"
            ><i class="fa fa-download" aria-hidden="true"></i>Download</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="!hideHeader">
  <h3>{{ title }}</h3>
  <app-breadcrumb></app-breadcrumb>
</div>
<div *ngIf="nameTitle" class="row">
  <div class="header-title-label">{{ nameTitle }} :</div>
  <div style="padding-left: 2px">{{ nameTitleValue }}</div>
  <br />
</div>
<mat-divider *ngIf="dividerNotRequired"></mat-divider>
