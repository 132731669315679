<div class="container">
  <mat-drawer-container>
    <!--<mat-drawer mode='side' [opened]='sidebarOpen'>
        <app-sidenavbar></app-sidenavbar>
    </mat-drawer>-->
    <mat-drawer-content>
      <!--------------------------------------Dashboard page template------------------------------>

      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="20px"
        class="stats-card"
      >
        <mat-card fxFlex="25">
          <app-card label="New users" total="12k" percentage="50"></app-card>
        </mat-card>

        <mat-card fxFlex="25">
          <app-card label="Referral" total="6k" percentage="20"></app-card>
        </mat-card>

        <mat-card fxFlex="25">
          <app-card
            label="User retention"
            total="10k"
            percentage="40"
          ></app-card>
        </mat-card>

        <mat-card fxFlex="25">
          <app-card
            label="User engagement"
            total="8k"
            percentage="38"
          ></app-card>
        </mat-card>
      </div>

      <br />

      <mat-divider></mat-divider>

      <br />

      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="20px"
      >
        <div class="tableclass" fxFlex="60">
          <div style="overflow-x: scroll" class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">
              <!-- Position Column -->
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef>Vertical</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.Vertical }}
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Allocated</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.Allocated }}
                </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="weight">
                <th mat-header-cell *matHeaderCellDef>Scheduled</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.Scheduled }}
                </td>
              </ng-container>

              <!-- Symbol Column -->

              <ng-container matColumnDef="symbol">
                <th mat-header-cell *matHeaderCellDef>Assessed</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.Assessed }}
                </td>
              </ng-container>

              <ng-container matColumnDef="a">
                <th mat-header-cell *matHeaderCellDef>Invoiced</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.Invoiced }}
                </td>
              </ng-container>
              <ng-container matColumnDef="b">
                <th mat-header-cell *matHeaderCellDef>Cancelled</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.Cancelled }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
          </div>
        </div>
        <div class="pichartclass" fxFlex="40">
          <app-piechart></app-piechart>
        </div>
      </div>

      <br />

      <mat-divider></mat-divider>

      <br />
      <app-areachart></app-areachart>
      <br />
    </mat-drawer-content>
  </mat-drawer-container>
</div>


