export const fields = {
    COURSES: [
        { field: 'QP Framework', datatype: 'string', required: true, regex: null, dataDescription: 'string (Ex: abcd)', patternDescription: '' },
        { field: 'QP Course', datatype: 'string', required: true, regex: null, dataDescription: 'string (Ex: abcd)', patternDescription: '' },
        { field: 'QP', datatype: 'string', required: true, regex: null, dataDescription: 'string (Ex: abcd)', patternDescription: '' },
    ],
    BUSINESS_VERTICAL: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'code', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'description', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
    ],
    RATE_MASTER: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'customer', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'service', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'code', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'description', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
    ],
    PROJECT: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'alias', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'short_name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'description', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'tags', datatype: 'json', required: false, regex: null, dataDescription: 'json (Ex: {"Course": ["Test", "Test 3"]}', patternDescription: '' },
    ],
    USERS: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'full_name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample)', patternDescription: '' },
        { field: 'phone', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: 9999999999)', patternDescription: '' },
        { field: 'email', datatype: 'string', required: true, regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, dataDescription: 'text (Ex: xyz@gmail.com)', patternDescription: 'text (Ex: xyz@gmail.com)' },
    ],
    CANDIDATE: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'full_name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample)', patternDescription: '' },
        { field: 'pincode', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: 560062)', patternDescription: '' },
        { field: 'district', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: Bengaluru)', patternDescription: '' },
        { field: 'state', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: Karnataka)', patternDescription: '' },
        { field: 'phone', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: 9999999999)', patternDescription: '' },
        { field: 'email', datatype: 'string', required: false, regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, dataDescription: 'text (Ex: xyz@gmail.com)', patternDescription: 'text (Ex: xyz@gmail.com)' },
    ],
}

