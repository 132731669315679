import { Directive, Input, OnInit, ElementRef, AfterViewInit, Renderer2 } from "@angular/core";
import { get } from "lodash";
import { UtilService } from "../services/util.service";

@Directive({
  selector: "[appAccessControl]",
})
export class AccessControlDirective implements AfterViewInit {
  @Input("moduleType") moduleType: string;
  @Input("pageType") pageType: string;
  @Input("permissionName") permissionName: string;
  @Input('elementValue') elementValue : any;
  @Input('additionalRowPermission') additionalRowPermission : string;
  @Input('conditionalOperator') conditionalOperator : string;
  @Input('conditionalValue') conditionalValue : string;
  
  constructor(private elementRef: ElementRef, private util: UtilService, private renderer: Renderer2,) { }

  ngAfterViewInit() {
    this.elementRef.nativeElement.disabled = true;
    this.renderer.addClass(this.elementRef.nativeElement, 'disabled');
    this.checkAccess();
  }
  checkAccess() {
    // TO DO : Local Storage will be removed
    const menuAccess = JSON.parse(this.util.decrypt(localStorage.getItem('menu_access')));
    /* Feature Level Permission Check */
    const featureAccess = this.util.getFeatureAccess(menuAccess, this.moduleType, this.pageType);
    if(this.permissionName){
      var hasPermission = featureAccess.includes(this.permissionName);
    }else {
      hasPermission = true;
    }
    /* Data Level Permission Check */
    if( this.elementValue && this.additionalRowPermission){
      var data = get(this.elementValue,this.additionalRowPermission,null);
      var dataLevelPermission = !!data ?  true : false ;
      switch (this.conditionalOperator) {
        case 'not':
          dataLevelPermission = !dataLevelPermission; 
          break;
        case 'equal':
          dataLevelPermission = data == this.conditionalValue ? true : false;
          break;
        case '':
          dataLevelPermission = data;
          break;
        default:
          dataLevelPermission = true;
          break;
      }
    } else {
      dataLevelPermission = true;
    }
    this.elementRef.nativeElement.disabled = hasPermission && dataLevelPermission ? false : true;
    if(!hasPermission) {
      this.renderer.addClass(this.elementRef.nativeElement, 'disabled');
    }else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'disabled');
    }
  }
}