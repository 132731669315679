import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AppNotificationsService } from '../../services/app-notifications.service';
import { MatMenu } from '@angular/material/menu';
import { UtilService } from '../../services/util.service';
import {
  goToAll,
  recentSearches,
  searchResults,
  searchResultsAll,
} from '../../constants/application-constants';
import { SocketService } from '@app/socket.service';

@Component({
  selector: 'app-globalsearch',
  templateUrl: './globalsearch.component.html',
  styleUrls: ['./globalsearch.component.css'],
  exportAs: 'globalSearchComponent',
  encapsulation: ViewEncapsulation.None,
})
export class GlobalsearchComponent implements OnInit {
  private readonly notificationService: AppNotificationsService;
  @ViewChild(MatMenu, { static: true }) menu: MatMenu;
  hoverActive: boolean = false;
  itemIndex = -1;
  itemKey = '';
  // @Input()  searchInput!: string;
  searchInput: string = '';
  @Output() searchInputChange = new EventEmitter<number>();
  recentSearches = recentSearches;
  goToAll = goToAll;
  searchResults = [];
  allRecent = recentSearches;
  allResults = searchResultsAll.length;

  constructor(
    service: AppNotificationsService,
    private util: UtilService,
    private socketService: SocketService,
    private notificationsService: AppNotificationsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.notificationService = service;
    this.notificationService.menuEmitter.subscribe(this.toggleMenu.bind(this));
  }

  ngOnInit() {}

  toggleMenu(): void {
    if (this.searchInput?.length >= 3) {
      this.searchResults = searchResults;
      this.recentSearches = this.recentSearches.slice(0, 3);
    } else {
      this.searchResults = [];
      this.recentSearches = this.allRecent;
    }
  }
  onSearchInputChange() {
    this.notificationsService.open();
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }
}
