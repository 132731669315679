<div class="container">
  <div class="row">
    <div class="col-lg-3">
      <div class="box-search">
        <div class="font-weight-bold">On this page</div>

        <div class="pr-3 mt-3">
          <div *ngFor="let item of tags | keyvalue : orderOriginal; let index = i" class="ml-2">
            <div class="mb-3">
              {{ item.key }} ({{item.value}})
            </div>
          </div>
        </div>
      </div>
      <div class="box-search mt-4" *ngIf="isSearchInput">
        <div class="font-weight-bold">Recent Searches</div>
        <div class="pr-3 mt-3">
          <div *ngFor="let search of recentSearches; let index = i">
            <div class="mb-3">
              <mat-icon class="mr-2">search</mat-icon>{{ search.item
              }}<span *ngIf="search.module" class="module"
                >in {{ search.module }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-8 ml-4">
      <div class="input-group mb-3">
        <input
          type="search"
          class="form-control"
          placeholder="Search"
          [(ngModel)]="searchInput"
          [matAutocomplete]="auto"
          (change)="onInputChange()"
        />
        <div class="input-group-prepend">
          <i
            class="input-group-text fas fa-search"
            id="basic-addon1"
            (click)="search()"
          ></i>
        </div>
      </div>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of options" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
      <div class="d-flex justify-content-between" *ngIf="isSearchInput">
      <h2 >You searched for: {{ sub }}</h2>
      <div class="all-results">All results ({{searchResults?.length}})</div>
    </div>
    <div class="d-flex justify-content-between" *ngIf="!isSearchInput">
      <h2>Recent Searches</h2>
      <div class="all-results">All results ({{recentSearches?.length}})</div>
    </div>
      <div class="mt-3">
        <mat-accordion>
          <div *ngFor="let search of showResults" class="mb-3">
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> {{ search.item }}</mat-panel-title>
                <mat-panel-description *ngIf="search.module">
                  {{ search.module }}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <p>Show some attributes related to search here</p>
            </mat-expansion-panel>
          </div>
        </mat-accordion>
        <mat-paginator
          [length]="length"
          (page)="onChangePage($event)"
          [pageSize]="pageSize"
          [pageSizeOptions]="[5, 10, 25]"
          aria-label="Select page"
          class="mb-5"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
