import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import {
  goToAll,
  recentSearches,
  searchResultsAll,
} from '@app/shared/constants/application-constants';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit {
  goToAll = goToAll;
  recentSearches = recentSearches;
  panelOpenState = false;
  searchResults = searchResultsAll;
  pageSize = 5;
  showResults = [];
  tags = {
    Candidates: 0,
    Customers: 0,
    Projects: 0,
    Courses: 0,
    Questions: 0,
    'Question Bank': 0,
    Leads: 0,
  };
  sub: any;
  isSearchInput = false;
  length = 0;
  options: string[] = [];
  searchInput: string = '';
  constructor(private router: ActivatedRoute) {}

  ngOnInit(): void {
    this.sub = this.router.snapshot.paramMap.get('key');
    if (this.sub) {
      this.isSearchInput = true;
      this.length = this.searchResults.length;
      this.showResults = this.searchResults.slice(0, this.pageSize);
      this.showResults.forEach((item) => {
        if (item.module) {
          this.tags[item.module] = this.tags[item.module] + 1;
        }
      });
    } else {
      this.showResults = this.recentSearches.slice(0, this.pageSize);
      this.length = this.recentSearches.length;
      this.showResults.forEach((item) => {
        if (item.module) {
          this.tags[item.module] = this.tags[item.module] + 1;
        }
      });
    }
  }

  onChangePage(pe: PageEvent) {
    this.showResults = this.searchResults.slice(
      pe.pageIndex * pe.pageSize,
      pe.pageIndex * pe.pageSize + pe.pageSize
    );
    this.showResults.forEach((item) => {
      if (item.module) {
        this.tags[item.module] = this.tags[item.module] + 1;
      }
    });
  }

  onInputChange() {
    this.options = ['John Doe', 'John Krasinski', 'John'];
  }

  orderOriginal = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return a.value > b.value ? -1 : (b.value > a.value ? 1 : 0);
  }

  search() {
    this.sub = this.searchInput;
    this.isSearchInput = true;
    this.showResults = [
      { item: 'John Doe', module: 'Candidates' },
      { item: 'John Doe I', module: 'Candidates' },
    ];
    this.length = 2;
    this.showResults.forEach((item) => {
      if (item.module) {
        this.tags[item.module] = this.tags[item.module] + 1;
      }
    });
  }
}
