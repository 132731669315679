import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { URL } from '@app-shared/constants/service-urls';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private api: ApiService) { }

  downloadSampleFile(type: any) {
   return this.api.get(`${URL.ADMIN.DOWNLOAD_SAMPLE_FILE}?sampleOf=${type}`,{responseType: 'blob'});
  };
}
