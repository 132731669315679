import { Injectable } from '@angular/core';
import { URL } from '@app-shared/constants/service-urls';
import { ApiService } from '@app-shared/services/api.service';
import { APIRequest } from '@app-shared/services/req-res.types';
import { UtilService } from '@app-shared/services/util.service';

@Injectable({
  providedIn: 'root',
})
export class FinanceService {
  constructor(private api: ApiService, private util: UtilService) {}

  get jwt(): string | null {
    return localStorage.getItem('JwtToken');
  }

  get userId(): number {
    return parseInt(localStorage.getItem('UserId'));
  }

  get userRoleId(): number {
    return parseInt(localStorage.getItem('UserRoleId'));
  }
  get orgID(): string | null {
    const value = localStorage.getItem('OrgId');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  /**
   * get Rates
   */
  getRates(fromDate?: string, toDate?: string) {
    if (!fromDate && !toDate) {
      return this.api.get(`${URL.FINANCE.RATE_MASTER}`);
    }
    return this.api.get(`${URL.FINANCE.RATE_MASTER}?from_date=${fromDate}&to_date=${toDate}`);
  }

  getRate(id: number) {
    return this.api.get(`${URL.FINANCE.RATE_MASTER}/${id}`);
  }

  addorEditRateMaster(data: any, id: any) {
    const postData: APIRequest = {
      requestBody: {
        ...data,
      },
    };

    if (id) {
      return this.api.put(`${URL.FINANCE.RATE_MASTER}/${parseInt(id)}`, postData)
    }
    return this.api.post(URL.FINANCE.RATE_MASTER, postData)
  }

  getInvoices(fromDate?: string, toDate?: string) {
    if (fromDate && toDate) {
      return this.api.get(`${URL.FINANCE.INVOICES}?from_date=${fromDate}&to_date=${toDate}`);
    }
    return this.api.get(`${URL.FINANCE.INVOICES}`);
  }

  getBills(fromDate?: string, toDate?: string) {
    if (fromDate && toDate) {
      return this.api.get(`${URL.FINANCE.BILLS}?from_date=${fromDate}&to_date=${toDate}`);
    }
    return this.api.get(`${URL.FINANCE.BILLS}`);
  }

  getInvoice(id: string, customerId?: string, fromDate?: string, toDate?: string) {
    if (customerId) {
      return this.api.get(`${URL.FINANCE.INVOICES}/${customerId}/${id}`);
    }
    if (fromDate && toDate) {
      return this.api.get(`${URL.FINANCE.INVOICES}/${id}?from_date=${fromDate}&to_date=${toDate}`);
    }
    return this.api.get(`${URL.FINANCE.INVOICES}/${id}`);
  }

  getReceipts() {
    return this.api.get(`${URL.FINANCE.RECEIPTS}`);
  }

  getReceipt(id: string) {
    return this.api.get(`${URL.FINANCE.RECEIPTS}/${id}`);
  }

  addorEditInvoices(data: any, id: any) {
    const postData: APIRequest = {
      requestBody: {
        ...data,
      },
    };

    if (id) {
      return this.api.put(`${URL.FINANCE.INVOICES}/${parseInt(id)}`, postData)
    }
    return this.api.post(URL.FINANCE.INVOICES, postData)
  }

  addorEditReceipt(data: any, id: any) {
    const postData: APIRequest = {
      requestBody: {
        ...data,
      },
    };

    if (id) {
      return this.api.put(`${URL.FINANCE.RECEIPTS}/${parseInt(id)}`, postData)
    }
    return this.api.post(URL.FINANCE.RECEIPTS, postData)
  }

  deleteReceipt(id: string) {
    return this.api.delete(`${URL.FINANCE.RECEIPTS}`, id);
  }

  getCustomers() {
    return this.api.get(URL.SALES.CUSTOMERS);
  }

  getAddresses() {
    return this.api.get(URL.FINANCE.BILLING_ADDRESS)
  }

  addAddress(data: any) {
    const postData: APIRequest = {
      requestBody: {
        ...data,
      },
    };

    return this.api.post(`${URL.FINANCE.BILLING_ADDRESS}`, postData)
  }

  getRateFilter(customer: number, service: string, date: string) {
    return this.api.get(`${URL.FINANCE.RATE_MASTER}?customer=${customer}&service=${service}&invoice_date=${date}`);
  }

  addorEditBills(data: any, id: any) {
    const postData: APIRequest = {
      requestBody: {
        ...data,
      },
    };

    if (id) {
      return this.api.put(`${URL.FINANCE.BILLS}/${parseInt(id)}`, postData)
    }
    return this.api.post(URL.FINANCE.BILLS, postData)
  }

  addorEditPayments(data: any, id: any) {
    const postData: APIRequest = {
      requestBody: {
        ...data,
      },
    };

    if (id) {
      return this.api.put(`${URL.FINANCE.PAYMENTS}/${parseInt(id)}`, postData)
    }
    return this.api.post(URL.FINANCE.PAYMENTS, postData)
  }

  getPayments(fromDate?: string, toDate?: string) {
    if (fromDate && toDate) {
      return this.api.get(`${URL.FINANCE.PAYMENTS}?from_date=${fromDate}&to_date=${toDate}`);
    }
    return this.api.get(`${URL.FINANCE.PAYMENTS}`);
  }

  getPayment(id: string) {
    return this.api.get(`${URL.FINANCE.PAYMENTS}/${id}`);
  }

  deletePayment(id: string) {
    return this.api.delete(`${URL.FINANCE.PAYMENTS}`, id);
  }
}
