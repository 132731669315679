<div [matMenuTriggerFor]="globalSearchMenu" #menuTrigger="matMenuTrigger"></div>
<mat-menu #globalSearchMenu="matMenu" xPosition="before" class="search-menu">
  <div class="search-menu-box">
    <input
      type="search"
      class="form-control"
      placeholder="Search"
      [(ngModel)]="searchInput"
      (input)="onSearchInputChange()"
      (click)="$event.stopPropagation()"
    />
    <div class="box">
      <div>
        <div *ngIf="searchResults?.length">
          <div
            class="d-flex justify-content-between heading pl-3 pr-3 font-weight-bold"
          >
            <div>SEARCH RESULTS</div>
            <div>All results ({{ allResults }})</div>
          </div>
          <div class="pl-3 pr-3 mt-3">
            <div *ngFor="let search of searchResults; let index = i">
              <div
                class="mb-3"
                (mouseover)="
                  hoverActive = true; itemIndex = i; itemKey = search.item
                "
                (mouseout)="hoverActive = false; itemIndex = -1; itemKey = ''"
                [ngStyle]="
                  hoverActive &&
                  i === itemIndex &&
                  itemKey == search.item && {
                    'background-color': 'rgb(221, 220, 231)'
                  }
                "
              >
                <mat-icon class="mr-2">search</mat-icon>{{ search.item
                }}<span *ngIf="search.module" class="module"
                  >in {{ search.module }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="heading pl-3 font-weight-bold">RECENT SEARCHES</div>
        <div class="pl-3 pr-3 mt-3">
          <div *ngFor="let search of recentSearches; let index = i">
            <div
              class="mb-3"
              (mouseover)="
                hoverActive = true; itemIndex = i; itemKey = search.item
              "
              (mouseout)="hoverActive = false; itemIndex = -1; itemKey = ''"
              [ngStyle]="
                hoverActive &&
                i === itemIndex &&
                itemKey == search.item && {
                  'background-color': 'rgb(221, 220, 231)'
                }
              "
            >
              <mat-icon class="mr-2">search</mat-icon>{{ search.item
              }}<span *ngIf="search.module" class="module"
                >in {{ search.module }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="footer">
          <a href="#" [routerLink]="['/search', { key: this.searchInput }]"
            >See all results</a
          >
        </div>
      </div>
    </div>
  </div>
</mat-menu>
