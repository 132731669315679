export const successMessages = {
  CREATE_ORG: {
    "0000": {
      "en": "Organization has been successfully created!",
    },
  },
  UPDATE_ORG: {
    "0000": {
      "en": "Organization has been successfully updated!",
    },
  },
  DELETE_ORG: {
    "0000": {
      "en": "Organization has been successfully deleted!",
    },
  },
  UPDATE_ORG_STATUS: {
    "0000": {
      "en": "Organization status has been successfully updated!",
    },
  },
  ADD_ADMIN: {
    "0000": {
      "en": "Admin has been successfully created!",
    }
  },
  UPDATE_ADMIN: {
    "0000": {
      "en": "Admin has been successfully updated!",
    }
  },
  UPDATE_ADMIN_STATUS: {
    "0000": {
      "en": "Admin status has been successfully updated!",
    }
  },
  UPDATE_ADMIN_ROLE: {
    "0000": {
      "en": "Admin role has been successfully updated!"
    }
  },
  DELETE_ADMIN: {
    "0000": {
      "en": "Admin has been successfully deleted!"
    }
  },
  ADD_MOU: {
    "0000": {
      "en": "MOU has been successfully added!",
    }
  },
  UPDATE_MOU: {
    "0000": {
      "en": "MOU has been successfully updated!",
    }
  },
  ADD_USER_ROLE: {
    "0000": {
      "en": "User role has been successfully created!",
    }
  },
  UPDATE_USER_ROLE: {
    "0000": {
      "en": "User role has been successfully updated!",
    }
  },
  UPDATE_USER_ROLE_STATUS: {
    "0000": {
      "en": "User role status has been successfully updated!",
    }
  },
  UPDATE_USER_ROLE_PERMISSION: {
    "0000": {
      "en": "User role permissions has been successfully updated!",
    }
  },
  CREATE_USER_CATEGORY: {
    "0000": {
      "en": "User category has been successfully created!",
    }
  },
  UPDATE_USER_CATEGORY: {
    "0000": {
      "en": "User category has been successfully updated!",
    }
  },
  SALES_VISIBILITY_MSG: {
    "0000": {
      "en": "Kindly note that for all users of this category email address will be auto created as a mandatory custom attributes.",
    }
  },
  DELETE_USER_CATEGORY: {
    "0000": {
      "en": "User category has been successfully deleted!",
    }
  },
  UPDATE_USER_CATEGORY_STATUS: {
    "0000": {
      "en": "User category status has been successfully updated!",
    }
  },
  CREATE_USER_CATEGORY_ATTRIBUTES: {
    "0000": {
      "en": "User category attributes has been successfully created!",
    }
  },
  UPDATE_USER_CATEGORY_ATTRIBUTES: {
    "0000": {
      "en": "User category attributes has been successfully updated!",
    }
  },
  DELETE_USER_CATEGORY_ATTRIBUTES: {
    "0000": {
      "en": "User category attributes has been successfully deleted!",
    }
  },
  CREATE_ORG_USER: {
    "0000": {
      "en": "User has been successfully created. <br> Kindly ensure that values are provided for all the custom attributes marked as mandatory in case this user needs to be listed in any other module.",
    }
  },
  UPDATE_ORG_USER: {
    "0000": {
      "en": "User has been successfully updated. <br> Kindly ensure that values are provided for all the custom attributes marked as mandatory in case this user needs to be listed in any other module.",
    }
  },
  UPADTE_ORG_USER_STATUS: {
    "0000": {
      "en": "User status has been successfully updated!",
    }
  },
  CREATE_ORG_USER_ATTR_VALUES: {
    "0000": {
      "en": "User custom attributes values has been successfully created!",
    }
  },
  UPDATE_ORG_USER_ATTR_VALUES: {
    "0000": {
      "en": "User custom attributes values has been successfully updated!",
    }
  },
  CREATE_ORG_USER_CREDENTIAL: {
    "0000": {
      "en": "User credentials has been successfully created!",
    }
  },
  CREATE_INDUSTRY_VERTICAL: {
    "0000": {
      "en": "Industry vertical has been successfully created!",
    }
  },
  UPDATE_INDUSTRY_VERTICAL: {
    "0000": {
      "en": "Industry vertical has been successfully updated!",
    }
  },
  UPDATE_INDUSTRY_VERTICAL_STATUS: {
    "0000": {
      "en": "Industry vertical status has been successfully updated!",
    }
  },
  CREATE_BUSINESS_VERTICAL: {
    "0000": {
      "en": "Business vertical has been successfully created!",
    }
  },
  UPDATE_BUSINESS_VERTICAL: {
    "0000": {
      "en": "Business vertical has been successfully updated!",
    }
  },
  UPDATE_BUSINESS_VERTICAL_STATUS: {
    "0000": {
      "en": "Business vertical status has been successfully updated!",
    }
  },
  CREATE_PRODUCT: {
    "0000": {
      "en": "Product has been successfully created!",
    }
  },
  UPDATE_PRODUCT: {
    "0000": {
      "en": "Product has been successfully updated!",
    }
  },
  UPDATE_PRODUCT_STATUS: {
    "0000": {
      "en": "Product status has been successfully updated!",
    }
  },
  CREATE_SALES_LEAD: {
    "0000": {
      "en": "Sales lead has been successfully created!",
    }
  },
  UPDATE_SALES_LEAD: {
    "0000": {
      "en": "Sales lead has been successfully updated!",
    }
  },
  UPDATE_SALES_LEAD_STATUS: {
    "0000": {
      "en": "Sales lead status has been successfully updated!",
    }
  },
  CREATE_SALES_OPPORTUNITY: {
    "0000": {
      "en": "Sales opportunity has been successfully created!",
    }
  },
  UPDATE_SALES_OPPORTUNITY: {
    "0000": {
      "en": "Sales opportunity has been successfully updated!",
    }
  },
  UPDATE_SALES_OPPORTUNITY_STATUS: {
    "0000": {
      "en": "Sales opportunity status has been successfully updated!",
    }
  },
  CREATE_OPPORTUNITY_MOU: {
    "0000": {
      "en": "Opportunity MOU has been successfully added!",
    }
  },
  UPDATE_OPPORTUNITY_MOU: {
    "0000": {
      "en": "Opportunity MOU has been successfully updated!",
    }
  },
  UPDATE_CUSTOMER_STATUS: {
    "0000": {
      "en": "Customer status has been successfully updated!",
    }
  },
  UPDATE_REASON_FOR_DROPPING: {
    "0000": {
      "en": "Reason for dropping has been successfully updated!",
    }
  },
  UPDATE_SALES_CUSTOMER: {
    "0000": {
      "en": "Sales customer has been successfully updated!",
    }
  },
  CREATE_SALES_CUSTOMER: {
    "0000": {
      "en": "Sales customer has been successfully created!",
    }
  },
  CREATE_CUSTOMER_GROUPS: {
    "0000": {
      "en": "Sales customer groups has been successfully created!",
    }
  },
  UPDATE_CUSTOMER_GROUPS: {
    "0000": {
      "en": "Sales customer groups has been successfully updated!",
    }
  },
  CREATE_SALES_SCHEME: {
    "0000": {
      "en": "Sales scheme has been successfully created!",
    }
  },
  UPDATE_SALES_SCHEME: {
    "0000": {
      "en": "Sales scheme has been successfully updated!",
    }
  },
  UPLOAD_SALES_LEADS_DATA: {
    "0000": {
      "en": "Sales leads data has been successfully uploaded!",
    }
  },
  CREATE_COURSE_GROUP: {
    "0000": {
      "en": "Course group has been successfully created!",
    }
  },
  UPDATE_COURSE_GROUP: {
    "0000": {
      "en": "has been successfully updated!",
    }
  },
  UPDATE_COURSE_GROUP_STATUS: {
    "0000": {
      "en": "status has been successfully updated!",
    }
  },
  CREATE_COURSE_SUB_GROUP: {
    "0000": {
      "en": "Course sub group has been successfully created!",
    }
  },
  UPDATE_COURSE_SUB_GROUP: {
    "0000": {
      "en": "has been successfully updated!",
    }
  },
  UPDATE_COURSE_SUB_GROUP_STATUS: {
    "0000": {
      "en": "status has been successfully updated!",
    }
  },
  CREATE_COURSE: {
    "0000": {
      "en": "has been successfully created!",
    }
  },
  UPDATE_COURSE: {
    "0000": {
      "en": "has been successfully updated!",
    }
  },
  CREATE_COURSE_VERSION: {
    "0000": {
      "en": "version has been successfully created!",
    }
  },
  UPDATE_COURSE_VERSION: {
    "0000": {
      "en": "version has been successfully updated!",
    }
  },
  CREATE_COURSE_MODULE: {
    "0000": {
      "en": "has been successfully created!",
    }
  },
  UPDATE_COURSE_MODULE: {
    "0000": {
      "en": "has been successfully updated!",
    }
  },
  CREATE_COURSE_TOPIC: {
    "0000": {
      "en": "has been successfully created!",
    }
  },
  UPDATE_COURSE_TOPIC: {
    "0000": {
      "en": "has been successfully updated!",
    }
  },
  DELETE_COURSE_GROUP: {
    "0000": {
      "en": "has been successfully deleted!",
    }
  },
  DELETE_COURSE_SUB_GROUP: {
    "0000": {
      "en": "has been successfully deleted!",
    }
  },
  UPDATE_COURSE_VERSION_STATUS: {
    "0000": {
      "en": "status has been successfully updated!",
    }
  },
  UPDATE_COURSE_MODULE_STATUS: {
    "0000": {
      "en": "status has been successfully updated!",
    }
  },
  UPDATE_COURSE_TOPIC_STATUS: {
    "0000": {
      "en": "status has been successfully updated!",
    }
  },
  DELETE_COURSE_BY_ID: {
    "0000": {
      "en": "has been successfully deleted!",
    }
  },
  UPDATE_COURSE_STATUS: {
    "0000": {
      "en": "status has been successfully updated!",
    }
  },
  CREATE_QUESTION_BANK: {
    "0000": {
      "en": "Question bank has been successfully created!",
    }
  },
  UPDATE_QUESTION_BANK: {
    "0000": {
      "en": "Question bank has been successfully updated!",
    }
  },
  UPDATE_QUESTION_BANK_STATUS: {
    "0000": {
      "en": "Question bank status has been successfully updated!",
    }
  },
  CREATE_QUESTION_PAPER: {
    "0000": {
      "en": "Question paper has been successfully created!",
    }
  },
  UPDATE_QUESTION_PAPER: {
    "0000": {
      "en": "Question paper has been successfully updated!",
    }
  },
  UPDATE_QUESTION_PAPER_STATUS: {
    "0000": {
      "en": "Question paper status has been successfully updated!",
    }
  },
  BULK_UPLOAD: {
    "0000": {
        "en": "File upload request submitted successfully. You will be notified after completing the file upload and processing."
    },
  },
  DELETE_EVALUATION_CRITERIA: {
    "0000": {
      "en": "Evaluation criteria has been successfully deleted!",
    }
  },
  DELETE_PROJECT: {
    "0000": {
      "en": "Project has been successfully deleted!",
    }
  },
  UPDATE_QUESTION_PAPER_EVALUATION_CRITERIA_STATUS: {
    "0000": {
      "en": "Evaluation Criteria status has been successfully updated!",
    }
  },
  UPDATE_PROJECT_STATUS: {
    "0000": {
      "en": "Project status has been successfully updated!",
    }
  },
  UPDATE_RATE_MASTER: {
    "0000": {
      "en": "Rate master status has been successfully updated!",
    }
  },
  ADD_OR_EDIT_EVALUATION_CRITERIA: {
    "0000": {
      "en": "Evaluation Criteria has been successfully added/updated!",
    }
  },
  ADD_OR_EDIT_RATE_MASTER: {
    "0000": {
      "en": "Rate Master has been successfully added/updated!",
    }
  },
  ADD_OR_EDIT_PROJECT: {
    "0000": {
      "en": "Project has been successfully added/updated!",
    }
  },
  NOTIFICATION_SETTING_UPDATE: {
    "0000": {
      "en": "Your settings has been updated successfully!",
    }
  },
  ADD_OR_EDIT_INVOICES: {
    "0000": {
      "en": "Invoice has been successfully added/updated!",
    }
  },
  ADD_OR_EDIT_BILLS: {
    "0000": {
      "en": "Bill has been successfully added/updated!",
    }
  },
  ADD_OR_EDIT_RECEIPTS: {
    "0000": {
      "en": "Receipt has been successfully added/updated!",
    }
  },
  ADD_BILLING_ADDRESS: {
    "0000": {
      "en": "Billing address has been successfully added!",
    }
  },
  CANCEL_INVOICE: {
    "0000": {
      "en": "Invoice has been successfully cancelled!",
    }
  },
  DELETE_RECEIPT: {
    "0000": {
      "en": "Receipt has been successfully deleted!",
    }
  },
  IMAGE_UPLOAD: {
    "0000": {
      "en": "Your image has been uploaded successfully!",
    }
  },
  DOCUMENT_UPLOAD: {
    "0000": {
      "en": "Your document has been uploaded successfully!",
    }
  },
  ADD_OR_EDIT_PAYMENT: {
    "0000": {
      "en": "Payment has been successfully added/updated!",
    }
  },
  DELETE_PAYMENT: {
    "0000": {
      "en": "Payment has been successfully deleted!",
    }
  },
  UPDATE_QUESTION_STATUS: {
    "0000": {
      "en": "Question status has been successfully updated!",
    }
  },
  DELETE_QUESTION: {
    "0000": {
      "en": "Question has been successfully deleted!",
    }
  },
  ADD_RUBRICS: {
    "0000": {
      "en": "Rubrics has been successfully added/updated!",
    }
  },
  MAP_QUESTIONS: {
    "0000": {
      "en": "Questions has been successfully mapped!",
    }
  },
  SAVED_AS_DRAFT: {
    "0000": {
      "en": "Question Paper has been successfully saved as draft!",
    }
  },
  DELETE_FROM_DRAFT: {
    "0000": {
      "en": "Question has been successfully removed!",
    }
  },
  PUBLISH_QUESTION_PAPER: {
    "0000": {
      "en": "Question Paper has been successfully published!",
    }
  },
  CREATE_EXPENSE_TYPE: {
    "0000": {
      "en": "Expense type has been successfully created!",
    }
  },
  UPDATE_EXPENSE_TYPE: {
    "0000": {
      "en": "Expense type has been successfully updated!",
    }
  },
  CREATE_CANDIDATE: {
    "0000": {
      "en": "Candidate has been successfully added/updated!",
    }
  },
  CREATE_ASSESSMENT: {
    "0000": {
      "en": "Assessment has been successfully added/updated!",
    }
  },
  DELETE_ASSESSMENT: {
    "0000": {
      "en": "Assessment has been successfully deleted!",
    }
  },
};
