import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ListMouComponent } from '@app-admin/dialogs/list-mou/list-mou.component';
import { ManageAdminsComponent } from '@app-admin/dialogs/manage-admins/manage-admins.component';
import { URL } from '@app-shared/constants/service-urls';
import { ApiService } from '@app-shared/services/api.service';
import { APIRequest } from '@app-shared/services/req-res.types';
import { UtilService } from '@app-shared/services/util.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(
    private dialog: MatDialog,
    private util: UtilService,
    private api: ApiService
  ) {}

  get userRole(): string | null {
    const value = localStorage.getItem('ur');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  get jwt(): string | null {
    return localStorage.getItem('JwtToken');
  }

  get orgID(): string | null {
    const value = localStorage.getItem('OrgId');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  getOrgDetails(currentOrgID: number) {
    const postData: APIRequest = {
      requestBody: {
        orgID: currentOrgID,
      },
    };

    return this.api.post(URL.ADMIN.GET_ORG_BY_ID, postData);
  }

  getUserOrgId() {
    return this.orgID;
  }
  
  createOrUpdateOrg(data: any) {
    const postData: APIRequest = {
      requestBody: {
        name: data.name,
        geography_id: data.geography_id,
        pincode_id: data.pincode_id,
        is_ldap_integration_enabled: data.isLDAPrequired === 'true',
        logo_file_name: data.logoSource,
        industry_vertical_id: parseInt(data.indVertical),
        default_modules: data.default_modules,
        course_framework_ids: data.courseFrameworkIds,
        state_name: data.state,
        district_name: data.district,
        billing_address: data.billingAddress,
        org_id: data.org_id,
      },
    };

    return this.api.post(URL.ADMIN.CREATE_OR_UPDATE_ORG, postData);
  }

  manageAdminDialog(orgID: any) {
    const dialogRef = this.dialog.open(ManageAdminsComponent, {
      width: '100vw',
      data: orgID,
      autoFocus: false,
    });
    //console.log(this.dialog.openDialogs)
    dialogRef.afterClosed().subscribe((result) => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  manageMOUDialog(orgID: any) {
    const dialogRef = this.dialog.open(ListMouComponent, {
      width: '100vw',
      data: orgID,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  uploadIimage(data: any) {
    const postData: APIRequest = {
      requestBody: {
       ...data,
      },
    }
    return this.api.post(URL.ADMIN.UPLOAD_FILES, postData);
  }

  download(data: any) {
    const postData: APIRequest = {
      requestBody: {
       ...data,
      },
    }
    return this.api.post(URL.ADMIN.DOWNLOAD, postData);
  }
}
