import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@app/shared/services/api.service';
import { UtilService } from '@app/shared/services/util.service';
import { throwError } from 'rxjs';
import { URL } from '@app/shared/constants/service-urls';
import * as XLSX from 'xlsx';
import { APIRequest } from '@app/shared/services/req-res.types';
import { DownloadService } from '@app/shared/services/download.service';
import { USERS } from '@app/shared/constants/application-constants';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
})
export class UploadComponent {
  files: any[] = [];
  isError: boolean = false;
  uploading: boolean = false;
  validating: boolean = false;
  processingFile: string = '';
  rowCount: number = 0;
  errorList = [];
  uploaded: boolean = false;
  progress: number = 0;
  multisheets: boolean = false;
  processing: boolean = false
  fileStatus: 'Success' | 'Error'
  showProgress: boolean;
  successMsg: string;
  serviceError: string;
  counter: number = 0;
  counterInterval: any;
  headers: any = []
  processed: boolean = false

  constructor(
    public dialogRef: MatDialogRef<UploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { type: string, items: any },
    private util: UtilService,
    private api: ApiService,
    private downloadSrvc: DownloadService
  ) {}

  get orgID(): string | null{
    const value = localStorage.getItem('OrgId');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  onFileDrop($event: any[]) {
    if (this.files.length === 0) {
      if (Number($event[0]?.size) > 5000000) {
        this.processFilesList($event);
      } else {
        this.serviceError = this.util.getIntlErrorMessage("UPLOAD", 'file-size-error');
        this.util.simpleDialog({
          title: 'Error',
          message: this.serviceError
        });
      }
    }
  }

  fileBrowseHandler(files: any[]) {
    if (Number(files[0].size) < 5000000) {
      this.processFilesList(files);
    } else {
      this.serviceError = this.util.getIntlErrorMessage("UPLOAD", 'file-size-error');
      this.util.simpleDialog({
        title: 'Error',
        message: this.serviceError
      });
    }
  }

  validateHeaders = (sheet: any, type: string, sheetIndex: any) => {
    let missingHeaders = [];
    const requiredFields = this.util.getFieldList(type);
    requiredFields.forEach((item, index) => {
      const cell = sheet[XLSX.utils.encode_cell({ c: index, r: 0 })];
      let header = '';
      if (cell && cell.t) {
        header = XLSX.utils.format_cell(cell);
        this.headers.push(header)
        if (item.field !== header) {
          missingHeaders.push(item.field);
        }
      }
    });
    this.progress = 10;
    if (this.headers.length !== new Set([...this.headers])?.size) {
      const errorMsg = this.util.getIntlErrorMessage(
        'UPLOAD',
        'header-repeat'
      );

      this.errorList.push(errorMsg);
    }
    if (missingHeaders.length !== 0) {
      const errorMsg = this.util.getIntlErrorMessage(
        'UPLOAD',
        'header-mismatch'
      );

      this.errorList.push(
        this.multisheets
          ? errorMsg
              .replace(/^/, `Sheet ${sheetIndex} : `)
              .replace('$1', missingHeaders?.join(', '))
          : errorMsg.replace('$1', missingHeaders?.join(', '))
      );
    }
    setTimeout(() => {
      this.progress = 0;
    }, 10);
  };

  validateData = (object: any, type: string, fileIndex: string | number, sheetIndex: any) => {
    const requiredFields = this.util.getFieldList(type);
    const jsonObj = JSON.parse(JSON.stringify(object));
    for (const item of requiredFields) {
      let isError = false;
      for (const [index, cell] of jsonObj.entries()) {
        if (
          item.required &&
          (cell[item.field] === '' || cell[item.field] == undefined)
        ) {
          const errorMsg = this.util.getIntlErrorMessage('UPLOAD', 'required');
          this.errorList.push(
            errorMsg.replace(/^/, `Sheet ${sheetIndex} : `).replace('$1', index + 1).replace('$2', item.field)
          );
          isError = true;
        }
        if (isError) continue;
        if (item.datatype) {
          if (item.required && (cell[item.field] === '' || cell[item.field] == undefined)) {
          if (item.datatype === 'array') {
            if (
              cell[item.field]?.length > 1 &&
              !Array.isArray(cell[item.field]?.split(','))
            ) {
              isError = true;
            }
          } else if (item.datatype === 'json') {
            const checkIfJson = this.util.isJson(String(cell[item.field]))
            if (!checkIfJson) isError = true;
          } else {
            if (typeof cell[item.field] !== item.datatype) {
              isError = true;
            }
          }

          if (isError) {
            const errorMsg = this.util.getIntlErrorMessage(
              'UPLOAD',
              'datatype-mismatch'
            );
            this.errorList.push(
              errorMsg
                .replace(/^/, `Sheet ${sheetIndex} : `)
                .replace('$1', index + 1)
                .replace('$2', cell[item.field])
                .replace('$3', item.dataDescription)
                .replace('$4', item.field)
            );
          }
          }
        }
        if (isError) continue;
        if (item.regex && !item.regex.test(cell[item.field])) {
          const errorMsg = this.util.getIntlErrorMessage(
            'UPLOAD',
            'pattern-mismatch'
          );
          this.errorList.push(
            errorMsg
              .replace(/^/, `Sheet ${sheetIndex} : `)
              .replace('$1', index + 1)
              .replace('$2', cell[item.field])
              .replace('$3', item.patternDescription)
              .replace('$4', item.field)
          );
        }
        if (this.files[fileIndex]?.progress + 80 / this.rowCount > 0)
          this.progress = 100 - this.files[fileIndex]?.progress;
        else this.progress = 80 / this.rowCount;
      }
    }
  };

  startTimer() {
    this.counterInterval = setInterval(() => {
        this.counter++
    },1000)
  }

  parseExcel(file: Blob, type: string, fileIndex: number) {
    let isError = false;
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary',
      });
      this.progress = 5;
      this.multisheets = workbook.SheetNames?.length > 1;
      workbook.SheetNames.every(
        async function (sheetName, index) {
          const XL_row_object = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName]
          );
          this.progress = 5;
          this.rowCount = XL_row_object.length;
          if (this.rowCount === 0) {
            const errorMsg = this.util.getIntlErrorMessage(
              'UPLOAD',
              'empty-records'
            );
            this.errorList.push(
              this.multisheets
                ? errorMsg.replace(/^/, `Sheet ${index + 1} : `)
                : errorMsg
            );
          }
          await this.validateHeaders(workbook.Sheets[sheetName], type, index+1);

          setTimeout(() => {
            this.validateData(XL_row_object, type, fileIndex, index+1);
          }, 1000);

          this.processed = true
          return true;
        }.bind(this),
        this
      );
    };

    reader.onerror = function (ex) {
      return throwError(
        'There was an error while trying to read the file. Please try again later.'
      );
    };
    reader.readAsBinaryString(file);

    return isError;
  }

  async uploadFilesSimulator(index: number) {
    setTimeout(() => {
      this.uploading = true;
      setTimeout(() => {
        this.validating = true;
        if (index === this.files.length) {
          return;
        } else {
          this.parseExcel(this.files[0], this.data.type, index);
          const progressInterval = setInterval(() => {
            if (this.files[index].progress >= 100 || this.processed) {
              this.files[index].progress = 100;
              clearInterval(progressInterval);
              clearInterval(this.counterInterval);
              this.uploaded = true;
              this.processing = false;
              this.fileStatus = this.errorList?.length > 0 ? 'Error': 'Success';
              this.uploadFilesSimulator(index + 1);
            } else {
              this.files[index].progress += this.progress;
            }
          }, 5);
        }
      }, 1000);
    }, 1000);
  }

  processFilesList(files: Array<any>) {
    this.startTimer()
    this.errorList = [];
    this.rowCount = 0;
    this.progress = 0;
    this.uploaded = false;
    this.processing = false;
    this.multisheets = false;

    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.processing = true
    this.processingFile = this.files[0]?.name;
    this.uploadFilesSimulator(0);
  }

  formatBytes(bytes: number, decimals: number) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  downloadLogs() {
    let downloadText = {
      "File name": this.files[0].name,
      "File size": this.files[0].size,
      "Processing time": `${this.counter}s`,
      "Processed headers": this.headers.join(','),
      "Error list": this.errorList.join('\n')
    }
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(JSON.stringify(downloadText))}`);
    downloadLink.setAttribute('download', `${this.files[0].name.replace(/.xlsx/g, '')}_errors.txt`);
    document.body.appendChild(downloadLink);
    downloadLink.click(); 
  }
 
  uploadFile() {
    this.showProgress = true
    let formdata = new FormData();
    formdata.append('file', this.files[0]);
    let resp;
    if (!this.data.items) {
      resp = this.api.post(`${URL.ADMIN.UPLOAD_FILE}?tableName=${this.data.type}&orgId=${this.orgID}&type=formdata`, formdata)
    } else {
      if (this.data.type === USERS) {
        resp = this.api.post(`${URL.ADMIN.UPLOAD_FILE}?tableName=${this.data.type}&userRoleId=${this.data.items.roleId}&userCategoryId=${this.data.items.categoryId}&type=formdata`, formdata)
      }
    }
    
    resp.subscribe(
      (data:any) => {
        this.showProgress = false;
        this.successMsg = this.util.getSuccessMessage("BULK_UPLOAD", data.body.responseHead.statusCode, data.body.responseHead.statusDesc);
        this.util.simpleDialog({
          title: 'Updated',
          message: this.successMsg,
        });
        this.files = []
        this.dialogRef.close()
      }, (err: any) => {
        this.showProgress = false;
        if (err.responseHead){
          this.serviceError = this.util.getIntlErrorMessage("BULK_UPLOAD", err.responseHead.statusCode, err.responseHead.statusDesc);
          this.util.simpleDialog({
            title: 'Error',
            message: this.serviceError
          });
        }
      });
  }

  downloadSample() {
    this.downloadSrvc.downloadSampleFile(this.data.type).subscribe((data: any) => {
      let binaryData = [];
      binaryData.push(data.body);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: 'blob' })
      );
      downloadLink.setAttribute(
        'download',
        `${this.data.type}_sample.xlsx`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }
}
