import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

import { DialogService } from '@app-admin/dialogs/dialog.service';
import { CustomFormValidator } from '@app-shared/components/custom-validators';
import { UtilService } from '@app-shared/services/util.service';
import { AddOrEditService } from '@app/shared/table/addOrEdit.service';
import { Router } from '@angular/router';
import { C } from '@angular/cdk/keycodes';
import { AdminService } from '@app/admin/admin.service';
import { FOLDER_TYPES } from '@app/shared/constants/application-constants';

 @Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-upload-mou',
  templateUrl: './upload-mou.component.html',
  styleUrls: ['./upload-mou.component.css']
})
export class UploadMouComponent implements OnInit {

  mouForm: UntypedFormGroup;
  pageType: string;
  serviceError: string;
  validFileExtensions = ['png','jpg','jpeg','pdf'];
  agreementDateRanges = [];
  uploadedMouFile: string;
  isLoading=false;



  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    public dialogRef: MatDialogRef<UploadMouComponent>,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private util: UtilService,
    private dialogSrvc: DialogService,
    private cdr: ChangeDetectorRef,
    private route: Router,
    private adminSrvc: AdminService,
    private addOrEditService: AddOrEditService) { }

  ngOnInit(): void {
    this.pageType = 'id' in this.modalData ? 'Edit' : 'Add';
    this.agreementDateRanges = this.dialog.openDialogs[0].componentInstance.dataSource.data.map((i) => {
      return {
        start: moment(i.agreement_start_date),
        end: moment(i.agreement_expiry_date)
      };
    }); 
    this.mouForm = this.fb.group({
      agreement_start_date: [this.pageType === 'Edit' ? moment(this.modalData.agreement_start_date) : '', [Validators.required, CustomFormValidator.dateIsBetween(this.agreementDateRanges)]],
      agreement_expiry_date: [this.pageType === 'Edit' ? moment(this.modalData.agreement_expiry_date) : '', [Validators.required, CustomFormValidator.dateIsBetween(this.agreementDateRanges)]],
      agreement_file: ['', this.pageType === 'Add' ? [Validators.required, CustomFormValidator.fileExtValidator(this.validFileExtensions)] : []],
      agreement_desc: [this.pageType === 'Edit' ? this.modalData.agreement_desc : ''],
      agreementSource: [this.pageType === 'Edit'?this.modalData.agreement_file : '']
    });
   if(this.pageType == 'Edit') {
   }
    if (this.modalData.agreement_file) {
      const fileName = this.modalData.agreement_file.replace(/.*\/MOUAgreement\//g, '');
      const splitedFileName = fileName.split('/mou/');
      if (splitedFileName.length && splitedFileName[1]) {
        this.uploadedMouFile = splitedFileName[1];
      }
    }

    if (this.pageType === 'Edit'){
      this.mouForm.get('agreement_start_date').setValidators([Validators.required]);
      this.mouForm.get('agreement_expiry_date').setValidators([Validators.required]);
    }
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.uploadedMouFile = '';
      const file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = () => {
        this.mouForm.patchValue({
          agreementSource: reader.result
        });
     
      let reqBody = {
        folder: FOLDER_TYPES.MOU, 
        "file": this.mouForm.get('agreementSource').value
      }
      this.adminSrvc.uploadIimage(reqBody).subscribe(
        (data: any) => {
          const response = data.body.responseBody
          this.mouForm.patchValue({agreementSource:response.url});
          const responseHead = data.body.responseHead;
          const successMsg = responseHead ? this.util.getSuccessMessage('Image_upoad', responseHead.statusCode, responseHead.statusDesc) : responseHead;
          this.util.simpleDialog({
            title: 'success',
            message: successMsg,
          });
          this.isLoading = false;
        },
        (err: any) => {
          this.isLoading = false;
          this.serviceError = err.responseHead 
            ? this.util.getIntlErrorMessage("Image_upoad", err.responseHead.statusCode, err.responseHead.statusDesc)
            : err;
          this.util.simpleDialog({
            title : 'error',
            message : this.serviceError
          })
        }
      );
    };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
      reader.readAsDataURL(file);
    }
  }

  createMOU(){
    this.uploadedMouFile = '';
    const inputs = this.mouForm.value;
    const payload = {
      organization_id: this.modalData.organization_id || this.modalData.orgId,
      agreement_file: inputs.agreementSource,
      agreement_start_date: inputs.agreement_start_date.format('YYYY-MM-DD'),
      agreement_expiry_date: inputs.agreement_expiry_date.format('YYYY-MM-DD'),
      agreement_desc: inputs.agreement_desc || ''
    };
    payload.organization_id = parseInt(payload.organization_id, 10);
    if (this.modalData.id) {
      payload['id'] = this.modalData.id;
    }
    
    this.util.showLoader = true;
    this.addOrEditService.addOrEditMou( payload).subscribe(
      (data: any) => {
        const title = this.pageType === 'Add' ? 'Added' : 'Updated';
        const msgIdentifier = this.pageType === 'Add' ? 'ADD' : 'UPDATE';
        const responseHead = data.body.responseHead;
        const successMsg = responseHead ? this.util.getSuccessMessage(`${msgIdentifier}_MOU`, responseHead.statusCode, responseHead.statusDesc) : responseHead;
        this.util.simpleDialog({
          title: title,
          message: successMsg,
        });
        this.closeDialog();
        this.util.showLoader = false;
      },
      (err: any) => {
        this.serviceError = err.responseHead 
          ? this.util.getIntlErrorMessage("LIST_ORGS", err.responseHead.statusCode, err.responseHead.statusDesc)
          : err;
        this.util.showLoader = false;
        this.util.simpleDialog({
          title: 'Error',
          message: this.serviceError,
        });
      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
