import { Injectable, EventEmitter } from '@angular/core';
import { URL } from '@app/shared/constants/service-urls';
import { ApiService } from '@app/shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class AppNotificationsService {
  public menuEmitter: EventEmitter<boolean>;
  constructor(private api: ApiService) {
    this.menuEmitter = new EventEmitter<boolean>();
  }
  open(): void {
    this.menuEmitter.emit(true);
  }
  close(): void {
    this.menuEmitter.emit(false);
  }

  getNotifications(page:number, size: number) {
    return this.api.get(URL.NOTIFICATIONS.GET_ALL_NOTIFICATIONS,{"page":page,"size":size});
  }

  markAsRead(id?: number) {
    if (id) {
      return this.api.put(`${URL.NOTIFICATIONS.MARK_AS_READ}/${id}`)
    }
    return this.api.put(`${URL.NOTIFICATIONS.MARK_AS_READ}`)
  }
}
