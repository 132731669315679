import { Injectable } from '@angular/core';
import { URL } from '@app-shared/constants/service-urls';
import { ApiService } from '@app/shared/services/api.service';
import { APIRequest } from '@app/shared/services/req-res.types';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private api: ApiService) { }

  getAllNotificationEvents() {
   return this.api.get(`${URL.NOTIFICATIONS.NOTIFICATIONS_EVENTS}`);
  };

  getNotificationSettings() {
    return this.api.get(`${URL.NOTIFICATIONS.NOTIFICATION_SETTINGS}`);
  }

  updateNotificationSettings(id: number, data: any) {
    const postData: APIRequest = {
        requestBody: {
          ...data,
        },
      };
    return this.api.put(`${URL.NOTIFICATIONS.NOTIFICATION_SETTINGS}/${id}`, postData)
  }

  saveNotificationSettings(data: any) {
    const postData: APIRequest = {
        requestBody: {
          ...data,
        },
      };
    return this.api.post(`${URL.NOTIFICATIONS.NOTIFICATION_SETTINGS}`, postData)
  }
 }
