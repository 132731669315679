<div class="container" [ngClass]="{ 'p-0 m-0': isModal }">
  <custom-table
    [tableData]="dataSource"
    [tableColumns]="tableColumns"
    [tableTitle]="'Candidates'"
    [tableActions]="tableActions"
    [tableTitle]="title"
    [moduleType]="''"
    [pageType]="''"
    hideTableTitle="true"
    (tableActionEmitter)="tableAction($event)"
    (toogleEmitter)="toggleEmitterEvent($event.row, $event.checked)"
    (rowAction)="rowAction($event)"
    [isFilterable]="false"
    isFilterPresent="true"
    isdatePicker="true"
    [filterAttributes]="filterAttributes"
    [dateRangeDetails]="dateRangeDetails"
    [radioButtonCallBack]="radioButtonCallBack"
    isFilterPresent="true"
    isdatePicker="true"
    [filterAttributes]="filterAttributes"
    [selectedItems]="selectedItems"
  >
  </custom-table>
</div>
